import serviceCallActions from 'modules/ServiceCall/ServiceCall.actions';

const initialState = {
  list: [],
  data: {},
  creating: false,
  success: false,
  fetching: false,
  deleting: false,
  editing: false,
  canceling: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case serviceCallActions.FTCHING:
      return {
        ...state,
        fetching: true,
        editing: false,
        deleting: false,
        success: false,
        error: null
      };

    case serviceCallActions.CREATING:
      return {
        ...state,
        fetching: true,
        editing: false,
        creating: true,
        deleting: false,
        error: null,
        success: false
      };

    case serviceCallActions.UPDATING:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: true,
        success: false,
        deleting: false,
        error: null
      };

    case serviceCallActions.CANCEL:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        canceling: true,
        fetching: false,
        editing: true,
        success: false,
        deleting: false,
        error: null
      };

    case serviceCallActions.CANCEL_SUCCESS:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        canceling: false,
        success: true,
        deleting: false,
        error: null
      };

    case serviceCallActions.DELETING:
      return {
        ...state,
        deleting: true,
        success: false,
        error: null
      };

    case serviceCallActions.FETCH_ONE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    case serviceCallActions.CREATE_SUCCESS:
      return {
        ...state,
        fetching: true,
        data: action.payload,
        creating: false,
        editing: false,
        deleting: false,
        success: true,
        error: null
      };

    case serviceCallActions.SUBMIT_SIGNATURE:
      return {
        ...state,
        fetching: false,
        creating: false,
        editing: false,
        deleting: false,
        success: true,
        error: null
      };

    case serviceCallActions.SUBMIT_SIGNATURE_SUCCESS:
      return {
        ...state,
        fetching: false,
        creating: false,
        editing: false,
        deleting: false,
        success: false,
        error: null
      };

    case serviceCallActions.FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        success: true,
        error: null
      };

    case serviceCallActions.ERROR:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        success: false,
        error: action.payload
      };

    case serviceCallActions.DELETE_SUCCESS:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        success: true,
        deleting: false,
        error: null
      };

    case serviceCallActions.UPDATING_SUCCESS:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        success: true,
        deleting: false,
        error: null
      };

    default:
      return { ...state };
  }
};
export default reducer;
