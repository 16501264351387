import axios from 'axios';
import { base_url } from 'env';
import { getHeaders, parseAndShowMessage } from 'store/common/common';
import { store } from 'store/store';
import Geocode from 'react-geocode';
import qs from 'qs';

Geocode.setApiKey(process.env.REACT_APP_MAPS_API_KEY);

axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    options.paramsSerializer = function(params) {
      return qs.stringify(params);
    };
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'SITE';

const siteActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  FETCH_ALL_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  DELETING: `${prefix}_DELETING`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  UPDATING: `${prefix}_UPDATING`,
  UPDATING_SUCCESS: `${prefix}_UPDATING_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  getAll: (params = null) => dispatch => {
    dispatch({ type: siteActions.FETCHING });
    let query = {
      model: 'Site',
      queries: [
        {
          field: 'client_id',
          value: store.getState().login.user.client_id
        }
      ]
    };
    axios
      .post(base_url + 'v2/search', query, {
        params: params
      })
      .then(response =>
        dispatch({
          type: siteActions.FETCH_ALL_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: siteActions.ERROR, payload: error }));
  },
  getById: id => dispatch => {
    dispatch({ type: siteActions.FETCHING });
    let lat, lng;
    // let query = {
    //   model: 'Site',
    //   queries: [{ field: 'id', value: id }]
    // };

    axios
      .get(`${base_url}resources/Site/${id}`)
      .then(response => {
        Geocode.fromAddress(`${response.data.full_address}`)
          .then(res => {
            lat = res.results[0].geometry.location.lat;
            lng = res.results[0].geometry.location.lng;
          })
          .then(() =>
            dispatch({
              type: siteActions.FETCH_ONE_SUCCESS,
              payload: { ...response.data, latitude: lat, longitude: lng }
            })
          );
      })
      .catch(error => dispatch({ type: siteActions.ERROR, payload: error }));
  },
  getByModel: (model, modelId) => dispatch => {
    dispatch({ type: siteActions.FETCHING });

    axios
      .get(base_url + `site/${model}/${modelId}`)
      .then(response =>
        dispatch({
          type: siteActions.SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: siteActions.ERROR, payload: error }));
  },
  delete: id => dispatch => {
    dispatch({ type: siteActions.DELETING });

    axios
      .delete(base_url + `site/${id}`)
      .then(response =>
        dispatch({
          type: siteActions.DELETE_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: siteActions.ERROR, payload: error }));
  },
  update: (id, data) => dispatch => {
    dispatch({ type: siteActions.UPDATING });

    axios
      .put(base_url + `site/${id}`, data)
      .then(response => {
        parseAndShowMessage('success', response, 'Site Update');
        dispatch({
          type: siteActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Site Update');
        dispatch({ type: siteActions.ERROR, payload: error });
      });
  }
};

export default siteActions;
