import * as actionCreators from '../actionTypes';
import axios from 'axios';
import { base_url } from '../../env';
import { store } from '../store';
import { getHeaders } from './common';
import history from '../history';

export const commonActionTypes = {
  GET_TOTAL_BY_TYPE: 'GET_TOTAL_BY_TYPE',
  GET_TOTAL_BY_TYPE_SUCCESS: 'GET_TOTAL_BY_TYPE_SUCCESS',
  GET_TOTAL_BY_TYPE_FAIL: 'GET_TOTAL_BY_TYPE_FAIL',
  // past due
  GET_PAST_DUE_BY_TYPE: 'GET_PAST_DUE_BY_TYPE',
  GET_PAST_DUE_BY_TYPE_SUCCESS: 'GET_PAST_DUE_BY_TYPE_SUCCESS',
  GET_PAST_DUE_BY_TYPE_FAIL: 'GET_PAST_DUE_BY_TYPE_FAIL'
};

export const getPastDue = (model, id) => dispatch => {
  dispatch({ type: commonActionTypes.GET_TOTAL_BY_TYPE });

  axios
    .get(`invoices/${model}/${id}/balanceDue`, {
      headers: getHeaders()
    })
    .then(res => {
      dispatch({
        type: commonActionTypes.GET_PAST_DUE_BY_TYPE_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: commonActionTypes.GET_PAST_DUE_BY_TYPE_FAIL,
        payload: err
      });
    });
};

// export const getPayments = (model, id) => dispatch => {
//   dispatch({ type: commonActionTypes.GET_TOTAL_BY_TYPE });

//   axios
//     .get(`invoices/${model}/${id}/balanceDue`, {
//       headers: getHeaders()
//     })
//     .then(res => {
//       dispatch({
//         type: commonActionTypes.GET_PAST_DUE_BY_TYPE_SUCCESS,
//         payload: res.data
//       });
//     })
//     .catch(err => {
//       dispatch({
//         type: commonActionTypes.GET_PAST_DUE_BY_TYPE_FAIL,
//         payload: err
//       });
//     });
// };

export const getTotalRevenue = (model, id) => dispatch => {
  dispatch({ type: commonActionTypes.GET_PAST_DUE_BY_TYPE });

  axios
    .get(`invoices/${model}/${id}/total`, {
      headers: getHeaders()
    })
    .then(res => {
      dispatch({
        type: commonActionTypes.GET_TOTAL_BY_TYPE_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: commonActionTypes.GET_TOTAL_BY_TYPE_FAIL,
        payload: err
      });
    });
};

export const getCSNumber = () => {
  return dispatch => {
    dispatch({ type: actionCreators.CS_COUNT_CHECK });

    const data = {
      user_id: store.getState().login.user.id,
      client_id: store.getState().login.user.client_id,
      role_id: store.getState().login.user.role_id
    };
    axios
      .post(base_url + 'getTotalCsNo', {}, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.CS_COUNT_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.CLIENT_LIST_FAIL, payload: error })
      );
  };
};

export const handleSearch = term => dispatch => {
  dispatch({
    type: actionCreators.HANDLE_SEARCH,
    payload: term
  });
};

export const getCSList = () => {
  return dispatch => {
    dispatch({ type: actionCreators.CS_LIST_CHECK });

    const data = {
      user_id: store.getState().login.user.id,
      client_id: store.getState().login.user.client_id,
      role_id: store.getState().login.user.role_id
    };
    axios
      .get(base_url + 'CSList', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.CS_LIST_SUCCESS,
          payload: response.data.data
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.CS_LIST_FAIL, payload: error })
      );
  };
};

export const deleteClientElement = id => {
  return async dispatch => {
    dispatch({ type: actionCreators.CLIENT_DELETE_CHECK });

    const data = { id: id };

    await axios
      .post(base_url + 'clients/delete', data, {
        headers: getHeaders()
      })
      .then(response => {
        return dispatch({
          type: actionCreators.CLIENT_DELETE_SUCCESS,
          payload: response.data.data.clients
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.CLIENT_DELETE_FAIL, payload: error })
      );
  };
};

export const contactList = () => {
  return async dispatch => {
    dispatch({ type: actionCreators.GET_ALLUSERLIST_CHECK });
    const data = {
      user_id: store.getState().login.user.id,
      client_id: store.getState().login.user.client_id,
      role_id: store.getState().login.user.role_id
    };
    axios
      .post(base_url + 'sites/create', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_ALLUSERLIST_SUCCESS,
          payload: response.data.data.contacts
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.GET_ALLUSERLIST_FAIL,
          payload: 'ERROR'
        })
      );
  };
};

export const relationShipList = () => {
  return async dispatch => {
    dispatch({ type: actionCreators.GET_RELATIONSHIPS_CHECK });
    const data = { dealId: localStorage.getItem('deal_id') };
    axios
      .post(base_url + 'deal/getCsRelationships', data, {
        headers: getHeaders()
      })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_RELATIONSHIPS_SUCCESS,
          payload: response.data.data
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.GET_RELATIONSHIPS_FAIL,
          payload: 'ERROR'
        })
      );
  };
};

export const brandList = () => {
  return async dispatch => {
    dispatch({ type: actionCreators.GET_BRANDLIST_CHECK });
    const data = {
      user_id: store.getState().login.user.id,
      client_id: store.getState().login.user.client_id,
      role_id: store.getState().login.user.role_id
    };
    axios
      .post(base_url + 'products/getAllBrands', data, {
        headers: getHeaders()
      })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_BRANDLIST_SUCCESS,
          payload: response.data.data.brands
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.GET_BRANDLIST_FAIL, payload: 'ERROR' })
      );
  };
};

export const productList = () => {
  return async dispatch => {
    dispatch({ type: actionCreators.GET_PRODUCTLIST_CHECK });
    const data = {
      user_id: store.getState().login.user.id,
      client_id: store.getState().login.user.client_id,
      role_id: store.getState().login.user.role_id
    };
    axios
      .get(base_url + 'products', { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_PRODUCTLIST_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.GET_PRODUCTLIST_FAIL,
          payload: 'ERROR'
        })
      );
  };
};

export const profileEditAction = () => {
  return dispatch => {
    dispatch({ type: actionCreators.PROFILE_EDIT_CHECK });

    const data = {
      user_id: store.getState().login.user.id,
      role_id: store.getState().login.user.role_id,
      client_id: store.getState().login.user.client_id,
      id: store.getState().login.user.id
    };
    axios
      .post(base_url + 'editprofile', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.PROFILE_EDIT_SUCCESS,
          payload: response.data.data
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.PROFILE_EDIT_FAIL, payload: error })
      );
  };
};

export const profileUpdateSubmit = data => {
  return dispatch => {
    dispatch({ type: actionCreators.PROFILE_UPDATE_CHECK });

    const profiledata = {
      ...data,
      user_id: store.getState().login.user.id,
      role_id: store.getState().login.user.role_id,
      client_id: store.getState().login.user.client_id,
      id: store.getState().login.user.id
    };
    console.log('profiledata profiledata=>>', profiledata);
    axios
      .post(base_url + 'updateprofile', profiledata, {
        headers: getHeaders()
      })
      .then(response => {
        dispatch({
          type: actionCreators.PROFILE_UPDATE_SUCCESS,
          payload: response.data.data
        });
        return history.push('/dashboard');
      })
      .catch(error =>
        dispatch({ type: actionCreators.PROFILE_UPDATE_FAIL, payload: error })
      );
  };
};

//technician calender
export const techCalenderList = data => {
  return dispatch => {
    if (data == [] || data == null || data == undefined) {
      dispatch({
        type: actionCreators.GET_TECH_SCHEDULE_FAIL,
        payload: 'ERROR DATA'
      });
    } else {
      dispatch({
        type: actionCreators.GET_TECH_SCHEDULE_SUCCESS,
        payload: data
      });
    }
  };
};

// Delete Action
export const deleteAction = id => {
  return dispatch => {
    dispatch({
      type: actionCreators.DELETE_SUCCESS,
      payload: id
    });
  };
};

export const getTaxByZip = zip => {
  return dispatch => {
    dispatch({ type: actionCreators.GET_TAX_BY_ZIP_CHECK });
    axios
      .get(base_url + 'tax/' + zip, { headers: getHeaders() })
      .then(response =>
        dispatch({
          type: actionCreators.GET_TAX_BY_ZIP_SUCCESS,
          payload: response
        })
      )
      .catch(error =>
        dispatch({ type: actionCreators.GET_TAX_BY_ZIP_FAIL, payload: error })
      );
  };
};

export const submitPayment = data => {
  return async dispatch => {
    dispatch({ type: actionCreators.SUBMIT_PAYMENT_CHECK });

    // const data = {
    //   amount: amount,
    //   payment_method_id: paymentMethodId,
    //   contact_id: contactId
    // };
    await axios
      .post(base_url + 'payments/create/one-time-payment', data, {
        headers: getHeaders()
      })
      .then(response => {
        return dispatch({
          type: actionCreators.SUBMIT_PAYMENT_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.SUBMIT_PAYMENT_FAIL, payload: error })
      );
  };
};

export const addPaymentMethod = (model, modelID, data) => {
  return async dispatch => {
    dispatch({ type: actionCreators.ADD_PAYMENT_METHOD });

    await axios
      .post(`${base_url}paymentMethods/${model}/${modelID}`, data, {
        headers: getHeaders()
      })
      .then(response => {
        return dispatch({
          type: actionCreators.ADD_PAYMENT_METHOD_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.ADD_PAYMENT_METHOD_FAIL,
          payload: error
        })
      );
  };
};

export const getPayments = contactId => {
  return dispatch => {
    dispatch({ type: actionCreators.GET_PAYMENTS_CHECK });

    const data = {
      contact_id: store.getState().login.user.client_id
    };
    axios
      .post(base_url + 'payments/contact', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_PAYMENTS_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.GET_PAYMENTS_FAIL, payload: error })
      );
  };
};

export const getPaymentMethods = (modelId, model = 'Contact') => {
  return dispatch => {
    dispatch({ type: actionCreators.GET_PAYMENT_METHODS_CHECK });

    axios
      .get(`${base_url}resources/${model}/${modelId}/paymentMethods`, {
        headers: getHeaders()
      })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_PAYMENT_METHODS_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.GET_PAYMENT_METHODS_FAIL,
          payload: error
        })
      );
  };
};
