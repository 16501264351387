import * as actionCreators from '../actionTypes';
import PNotify from 'pnotify/dist/es/PNotify';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import React from 'react';
import { parseAndShowMessage } from 'store/common/common';
import { RUN_EMERGENCY_CONTACT_UPDATE, RUN_EMERGENCY_CONTACT_UPDATE_ERROR } from "../actionTypes";

const initialState = {
  dealList: [],
  payments: [],
  paymentMethods: [],
  contactList: [],
  centralStation: [],
  centralStationAddons: [],
  communicationsPackagesList: [],
  commPackagesAddons: [],
  dealTemplates: [],
  zoneList: [],
  testCategoriesList: [],
  subsciptionList: [],
  eventList: [],
  listloading: false,
  listerror: null,
  clear: false,

  deleteMessage: null,
  isDelete: false,

  submitMessage: null,
  isSubmitted: false,

  servicelistloading: false,
  servicelisterror: null,
  deal_info: null,
  contact_data: null,
  site_data: null,
  emergency_contacts_data: null,
  selectedPacakage: null,

  stage: null,
  step: 0,
  deal_type: null,
  deal_type_id: null,
  updated_deal_id: null,
  ispData: null,
  selected_items: null,
  buildingData: null,

  newDeal: null,
  newDealError: null,

  selectedItems: null,

  centralStationAddonsError: null,
  centralStationError: null,

  testCategoriesError: null,

  checkThePasscode: false,
  checkThePasscodeError: null,
  onTest: false,

  // Subsciptions

  getSubscriptionsError: null,

  // EVENT LIST

  eventListError: null,
  client_integration: null,
  company: null,
  equipments: null,
  equipmentTotal: null,
  subscriptions: null,
  serviceProvider: null,
  agencyContacts: null,
  account_number: null,
  acv_package: null,
  imei: null,

  alarm_updated: false,
  cs_updated: false,

  liveDealData: null,
  componentsToShow: [],


  actionButtonDisableStatus: false,

  systemsTypes: [],
  loading: false,
  loadingDealInfo: false,

  runEmergencyContactsUpdate: false,
};

const newDealReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.GET_DEAL_COMPONENTS_TO_SHOW_CHECK:
      return {
        ...state,
        componentsToShow: []
      };
    case actionCreators.GET_DEAL_COMPONENTS_TO_SHOW_SUCCESS:
      return {
        ...state,
        componentsToShow: action.payload
      };
    case actionCreators.GET_DEAL_COMPONENTS_TO_SHOW_FAIL:
      return { ...state };

    case actionCreators.ALL_SYSTEMS_SUCCESS:
      return {
        ...state,
        systemsTypes: action.payload
      };
    case actionCreators.ALL_SYSTEMS_ERROR:
      return {
        ...state,
        systemsTypes: [],
        error: action.payload,
      };
    case actionCreators.RUN_EMERGENCY_CONTACT_UPDATE:
      return {
        ...state,
        runEmergencyContactsUpdate: true
      };
    case actionCreators.RUN_EMERGENCY_CONTACT_UPDATE_ERROR:
      return {
        ...state,
        runEmergencyContactsUpdate: false,
      };

    case actionCreators.GET_DEAL_COMPONENTS_TO_SHOW_FAIL:
      return { ...state };
    case actionCreators.GET_DEAL_PAYMENTS_CHECK:
      return {
        ...state,
        listLoading: true,
        dealList: [],
        payments: [],
        paymentMethods: [],
        contactList: [],
        centralStation: [],
        deal_info: null,
        centralStationAddons: [],
        communicationsPackagesList: [],
        commPackagesAddons: [],
        dealTemplates: [],
        zoneList: [],
        testCategoriesList: [],
        subsciptionList: [],
        eventList: []
      };

    case actionCreators.GET_DEAL_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload
      };

    case actionCreators.GET_DEAL_PAYMENTS_FAIL:
      return { ...state };

    // event list
    case actionCreators.EVENT_LIST_CHECK: {
      // parseAndShowMessage(
      //   'notice',
      //   'Getting Fresh Event List From Central Station...',
      //   null,
      //   true
      // ); // pNotify = true
      return { ...state };
    }

    case actionCreators.EVENT_LIST_SUCCESS:
      return {
        ...state,
        eventList: action.payload.data
      };

    // deal list
    case actionCreators.DEAL_LIST_CHECK:
      return {
        ...state,
        listloading: true,
        isDelete: false,
        dealList: [],
        deal_info: null,
        payments: [],
        paymentMethods: [],
        contactList: [],
        centralStation: [],
        centralStationAddons: [],
        communicationsPackagesList: [],
        commPackagesAddons: [],
        dealTemplates: [],
        zoneList: [],
        testCategoriesList: [],
        subsciptionList: [],
        eventList: []
      };

    case actionCreators.DEAL_LIST_SUCCESS:
      return {
        ...state,
        dealList: action.payload,
        listloading: false,
        isDelete: false
      };
    case actionCreators.DEAL_LIST_FAIL:
      return {
        ...state,
        listerror: action.payload.request && action.payload.request.statusText,
        listloading: false,
        isDelete: false
      };

    // service List
    case actionCreators.SERVICE_LIST_CHECK:
      return {
        ...state,
        servicelistloading: true,
        dealList: [],
        payments: [],
        paymentMethods: [],
        contactList: [],
        centralStation: [],
        deal_info: null,
        centralStationAddons: [],
        communicationsPackagesList: [],
        commPackagesAddons: [],
        dealTemplates: [],
        zoneList: [],
        testCategoriesList: [],
        subsciptionList: [],
        eventList: [],
        isDelete: false
      };

    case actionCreators.SERVICE_LIST_SUCCESS:
      return {
        ...state,
        serviceList: action.payload,
        servicelistloading: false,
        isDelete: false
      };
    case actionCreators.SERVICE_LIST_FAIL:
      return {
        ...state,
        servicelisterror:
          action.payload.request && action.payload.request.statusText,
        servicelistloading: false,
        isDelete: false
      };

    // deal delete
    case actionCreators.CLEAR:
      return {
        ...state,
        dealList: [],
        listloading: false,
        listerror: null,
        clear: true,

        payments: [],

        deleteMessage: null,
        isDelete: false,

        submitMessage: null,
        isSubmitted: false,

        servicelistloading: false,
        servicelisterror: null,
        deal_info: null,
        contact_data: null,
        site_data: null,
        emergency_contacts_data: null,
        selectedPacakage: null,
        paymentMethods: null,

        stage: null,
        step: 0,
        deal_type: null,
        deal_type_id: null,
        updated_deal_id: null,
        ispData: null,
        selected_items: null,
        buildingData: null,

        newDeal: null,
        newDealError: null,
        stage2: null,
        stage2Error: null,

        stage3: null,
        stage3Error: null,

        stage4: null,
        stage4Error: null,

        contactList: [],
        centralStation: [],
        centralStationAddons: [],
        centralStationAddonsError: null,
        centralStationError: null,
        communicationsPackagesList: [],
        commPackagesAddons: [],
        dealTemplates: [],
        zoneList: [],

        testCategoriesList: [],
        testCategoriesError: null,

        checkThePasscode: false,
        checkThePasscodeError: null,
        onTest: false,
        onTestError: null,

        // Subsciptions

        subsciptionList: [],
        getSubscriptionsError: null,

        // EVENT LIST
        eventList: [],
        eventListError: null,
        client_integration: null,
        company: null,
        equipments: null,
        equipmentTotal: null,
        subscriptions: null,
        serviceProvider: null,
        agencyContacts: null,
        account_number: null,
        acv_package: null,
        imei: null,

        liveDealData: null
      };

    case actionCreators.DEAL_DELETE_CHECK:
      return {
        ...state,
        dealList: [],
        payments: [],
        paymentMethods: [],
        contactList: [],
        centralStation: [],
        centralStationAddons: [],
        communicationsPackagesList: [],
        commPackagesAddons: [],
        dealTemplates: [],
        zoneList: [],
        testCategoriesList: [],
        deal_info: null,
        subsciptionList: [],
        eventList: [],
        isDelete: false
      };
    case actionCreators.DEAL_DELETE_SUCCESS:
      return {
        ...state,
        isDelete: true,
        deleteMessage: action.payload
      };
    case actionCreators.DEAL_DELETE_FAIL:
      return {
        ...state,
        isDelete: false
      };

    // deal Add
    case actionCreators.DEAL_ADD_CHECK:
      return {
        ...state,
        dealList: [],
        payments: [],
        paymentMethods: [],
        contactList: [],
        centralStation: [],
        centralStationAddons: [],
        communicationsPackagesList: [],
        commPackagesAddons: [],
        deal_info: null,
        dealTemplates: [],
        zoneList: [],
        testCategoriesList: [],
        subsciptionList: [],
        eventList: [],
        isSubmitted: false
      };
    case actionCreators.DEAL_ADD_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.DEAL_ADD_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    //EDIT DEAL
    case actionCreators.EDITDEAL_CHECK:
      return {
        ...state,
        dealList: [],
        payments: [],
        paymentMethods: [],
        contactList: [],
        centralStation: [],
        deal_info: null,
        centralStationAddons: [],
        communicationsPackagesList: [],
        commPackagesAddons: [],
        dealTemplates: [],
        zoneList: [],
        testCategoriesList: [],
        subsciptionList: [],
        eventList: []
      };
    case actionCreators.EDITDEAL_SUCCESS:
      return {
        ...state,
        deal_info: {
          ...action.payload.deal_data.deal_alarm,
          // zoneList: action.payload.deal_data.deal_zone,
          equipments: action.payload.deal_data.equipments,
          deal_status: action.payload.deal_data.deal_status,
          deal_type: action.payload.deal_data.deal_type,
          fund_status: action.payload.deal_data.fund_status,
          imei: action.payload.deal_data.imei,
          ip_address: action.payload.deal_data.ip_address,
          receiver_number: action.payload.deal_data.receiver_number,
          site_id: action.payload.deal_data.site_id,
          stage_of_sale: action.payload.deal_data.stage_of_sale,
          subscriptions: action.payload.deal_data.subscriptions,
          updated_at: action.payload.deal_data.updated_at,
          client_id: action.payload.deal_data.client_id,
          company_id: action.payload.deal_data.company_id,
          contact_id: action.payload.deal_data.contact_id,
          created_at: action.payload.deal_data.created_at,
          created_by: action.payload.deal_data.created_by,
          cs_number: action.payload.deal_data.cs_number,
          customer_service_number:
            action.payload.deal_data.customer_service_number
        },
        paymentMethods: [
          {
            label: 'ach',
            account_number: action.payload.deal_data.account_number,
            routing_number: action.payload.deal_data.account_number
          },
          {
            label: 'cc',
            cardNumber: action.payload.deal_data.credit_card,
            ccv: action.payload.deal_data.credit_card_code,
            expirationMonth:
              action.payload.deal_data.credit_card_expiration_month,
            expirationYear: action.payload.deal_data.credit_card_expiration_year
          }
        ],
        contact_data: action.payload.contact_data,
        site_data: action.payload.site_data,
        emergency_contacts_data: action.payload.emergency_contacts_data,
        // selectedPkg:
        //   action.payload.selected_deal_data &&
        //   action.payload.selected_deal_type_data !== []
        //     ? action.payload.selected_deal_type_data.pop()
        //     : null
      };
    case actionCreators.EDITDEAL_FAIL:
      return {
        ...state,
        editDealError:
          action.payload.request && action.payload.request.statusText
      };

    //CHECK THE PASSCODE
    case actionCreators.CHECK_PASSCODE:
      return {
        ...state,
        dealList: [],
        payments: [],
        paymentMethods: [],
        contactList: [],
        centralStation: [],
        centralStationAddons: [],
        communicationsPackagesList: [],
        commPackagesAddons: [],
        dealTemplates: [],
        deal_info: null,
        zoneList: [],
        testCategoriesList: [],
        subsciptionList: [],
        eventList: []
      };
    case actionCreators.CHECK_PASSCODE_SUCCESS: {
      return {
        ...state,
        checkThePasscode:
          action.payload.request && action.payload.request.statusText
      };
    }
    case actionCreators.CHECK_PASSCODE_FAIL: {
      return {
        ...state,
        checkThePasscodeError: action.payload.request.statusText
      };
    }

    case actionCreators.GET_TEST_CATEGORIES:
      return { ...state };
    case actionCreators.GET_TEST_CATEGORIES_SUCCESS:
      return {
        ...state,
        testCategoriesList: action.payload.data
      };
    case actionCreators.GET_TEST_CATEGORIES_FAIL:
      parseAndShowMessage('error', action.payload, 'Get Test Categories');
      return {
        ...state,
        onTest: false,
        testCategoriesError:
          action.payload.request && action.payload.request.statusText
      };

    case actionCreators.SUBMIT_TEST_DETAILS:
      // parseAndShowMessage('notice', 'Putting On Test...', null, true); // pNotify = true
      return {
        ...state,
        onTest: false,
        onTestError: null,
        actionButtonDisableStatus: true

      };
    case actionCreators.SUBMIT_TEST_DETAILS_SUCCESS:
      parseAndShowMessage('success', action.payload, 'Submit Test Details');
      return {
        ...state,
        onTest: true,
        actionButtonDisableStatus: false
      };
    case actionCreators.SUBMIT_TEST_DETAILS_FAIL:
      parseAndShowMessage('error', action.payload, 'Submit Test Details');
      return {
        ...state,
        onTestError: action.payload.request && action.payload.request.statusText,
        actionButtonDisableStatus: false
      };

    case actionCreators.GET_DEALS_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        contactList: action.payload
      };

    case actionCreators.GET_DEALS_CONTACT_LIST_FAIL:
      return {
        ...state,
        contactList: ''
      };

    // GET CENTRAL STAITION
    case actionCreators.GET_CENTRAL_STATIONS:
      return { ...state };

    case actionCreators.GET_CENTRAL_STATIONS_SUCCESS:
      return {
        ...state,
        centralStation: action.payload
      };
    case actionCreators.GET_CENTRAL_STATIONS_FAIL:
      return {
        ...state,
        centralStationError:
          action.payload.request && action.payload.request.statusText
      };

    // GET CENTRAL STAITION Addons
    case actionCreators.GET_CENTRAL_STATION_ADDONS:
      return { ...state };

    case actionCreators.GET_CENTRAL_STATION_ADDONS_SUCCESS:
      return {
        ...state,
        centralStationAddons: action.payload.map(csAddon => ({
          label: csAddon.add_on_name,
          value: csAddon.id,
          csAddon,
          selected: false
        }))
      };
    case actionCreators.GET_CENTRAL_STATION_ADDONS_FAIL:
      return {
        ...state,
        centralStationAddonsError:
          action.payload.request && action.payload.request.statusText
      };

    // GET DEAL TEMPLATES
    case actionCreators.GET_DEAL_TEMPLATES:
      return { ...state };
    case actionCreators.GET_DEAL_TEMPLATES_SUCCESS:
      return {
        ...state,
        dealTemplates: action.payload
      };
    case actionCreators.GET_DEAL_SELECTED_ITEMS_SUCCESS:
      return {
        ...state,
        selectedItems: action.payload
      };
    case actionCreators.GET_DEAL_TEMPLATES_FAIL:
      return {
        ...state,
        dealTemplatesError:
          action.payload.request && action.payload.request.statusText
      };

    case actionCreators.GET_PAYMENT_METHOD:
      return {
        ...state,
        dealList: [],
        payments: [],
        paymentMethods: [],
        contactList: [],
        centralStation: [],
        centralStationAddons: [],
        communicationsPackagesList: [],
        commPackagesAddons: [],
        dealTemplates: [],
        zoneList: [],
        testCategoriesList: [],
        subsciptionList: [],
        eventList: []
      };
    case actionCreators.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload
      };
    case actionCreators.GET_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        paymentMethodError:
          action.payload.request && action.payload.request.statusText
      };

    case actionCreators.GET_ZONE_LIST: {
      // parseAndShowMessage('notice', 'Refreshing zone list...', null, true); // pNotify = true
      return { ...state, loading: true };
    }
    case actionCreators.GET_ZONE_LIST_SUCCESS: {
      if (action.payload?.is_monitored == false) {
        return {
          ...state,
          nonMonitoredZones: action.payload.data,
          loading: false
        };
      }

      return {
        ...state,
        zoneList: action.payload.data,
        loading: false
      };
    }

    case actionCreators.PUSH_ZONE_LIST: {
      // parseAndShowMessage('notice', 'Refreshing zone list...', null, true); // pNotify = true
      return { ...state };
    }
    case actionCreators.PUSH_ZONE_LIST_SUCCESS:
      return {
        ...state
      };

    case actionCreators.OVERVIEW_DETAILS_CHECK:
      return { ...state, loadingDealInfo: true };
    case actionCreators.OVERVIEW_DETAILS_SUCCESS:
      let selectedCS = action.payload?.deal_data
        ? action.payload.cList?.find(
          cs => action.payload.deal_data.client_central_station_id ? cs.id === action.payload.deal_data.client_central_station_id :
            cs.central_station_id == action.payload.deal_data.central_station_id
        )
        : {};
      let centralStation = {
        cs_number1: action.payload.cs_number,
        cs_number: action.payload.deal_data.cs_number,
        receiver_number: action.payload.deal_data.receiver_number,
        ...selectedCS,
        service_plan:
          action.payload.deal_data && action.payload.deal_data.service_plan,
        cs_add_ons: action.payload.cs_add_ons,
        receiver_number1: action.payload.receiver_number,
        sync: action.payload.deal_data && action.payload.deal_data.cs_sync,
        is_terminated:
          action.payload.deal_data && action.payload.deal_data.is_cs_terminated,
        template:
          action.payload.deal_data && action.payload.deal_data.cs_template,
        dispatch:
          action.payload.deal_data && action.payload.deal_data.cs_dispatch,
        verbal_passcode:
          action.payload.deal_data && action.payload.deal_data.verbal_passcode,
        device_type: action?.payload?.device_type,
        client_central_station_id: action.payload.deal_data.client_central_station_id ?? null,
      };
      let serviceProvider = {
        addons: action.payload.deal_data && action.payload.deal_alarm_acv_addon,
        account_number:
          action.payload.deal_data &&
          action.payload.deal_data.acv_account_number,
        acv_package:
          action.payload.deal_data && action.payload.deal_data.acv_package,
        imei: action.payload.imei,
        sync: action.payload.deal_data && action.payload.deal_data.acv_sync,
        username:
          action.payload.deal_data && action.payload.deal_data.user_email,
        password:
          action.payload.deal_data && action.payload.deal_data.user_password,
        is_terminated:
          action.payload.deal_data && action.payload.deal_data.is_acv_terminated
      };
      if (action.payload.deal_data) {
        if (action.payload.deal_data.central_station) {
          centralStation = {
            ...centralStation,
            ...action.payload.deal_data.central_station
          };
        }
        if (action.payload.deal_data.alarm_communication_vendor) {
          serviceProvider = {
            ...serviceProvider,
            ...action.payload.deal_data.alarm_communication_vendor
          };
        }
      }
      return {
        ...state,
        ...action.payload,
        loadingDealInfo: false,
        deal_info: { ...action.payload.deal_data, ...action.payload },
        dealTypeId: action.payload.deal_data && action.payload.deal_data.id,
        // client_integration: action.payload.client_integration,
        company: action.payload.company && action.payload.company,
        // zoneList: action.payload.deal_zone && action.payload.deal_zone,
        equipments: action.payload.equipments && action.payload.equipments,
        equipmentTotal:
          action.payload.equipment_total && action.payload.equipment_total,
        contact_data: action.payload.contact && action.payload.contact,
        site_data: action.payload.site && action.payload.site,
        subscriptions:
          action.payload.subscriptions && action.payload.subscriptions,
        emergency_contacts_data:
          action.payload.emergency_contact && action.payload.emergency_contact,
        centralStation: centralStation,
        serviceProvider: serviceProvider,
        paymentMethods: [
          {
            label: 'ach',
            account_number:
              action.payload.deal_data &&
              action.payload.deal_data.account_number,
            routing_number:
              action.payload.deal_data &&
              action.payload.deal_data.account_number
          },
          {
            label: 'cc',
            cardNumber:
              action.payload.deal_data && action.payload.deal_data.credit_card,
            ccv:
              action.payload.deal_data &&
              action.payload.deal_data.credit_card_code,
            expirationMonth:
              action.payload.deal_data &&
              action.payload.deal_data.credit_card_expiration_month,
            expirationYear:
              action.payload.deal_data &&
              action.payload.deal_data.credit_card_expiration_year
          }
        ],
        agencyContacts: [
          {
            ems_agency_name:
              action.payload.deal_data &&
              action.payload.deal_data.ems_agency_name,
            ems_agency_number:
              action.payload.deal_data &&
              action.payload.deal_data.ems_agency_number
          },
          {
            fire_agency_name:
              action.payload.deal_data &&
              action.payload.deal_data.fire_agency_name,
            fire_agency_number:
              action.payload.deal_data &&
              action.payload.deal_data.fire_agency_number
          },
          {
            police_agency_name:
              action.payload.deal_data &&
              action.payload.deal_data.police_agency_name,
            police_agency_number:
              action.payload.deal_data &&
              action.payload.deal_data.police_agency_number
          }
        ],
        ispData: {
          internet_provider:
            action.payload.deal_data &&
            action.payload.deal_data.internet_provider,
          router_username:
            action.payload.deal_data &&
            action.payload.deal_data.router_username,
          router_password:
            action.payload.deal_data && action.payload.deal_data.router_password
        },
        buildingData: {
          floors:
            action.payload.deal_data && action.payload.deal_data.no_of_stories,
          siding:
            action.payload.deal_data && action.payload.deal_data.what_is_siding,
          router_located:
            action.payload.deal_data && action.payload.deal_data.router_located
        },
        selected_items: action.payload.selected_items
      };
    case actionCreators.OVERVIEW_DETAILS_FAIL:
      return {
        ...state,
        loadingDealInfo: false,
        error: action.payload.request && action.payload.request.statusText
      };
    case actionCreators.DEAL_ALARM_GET_LIVE_DATA_SUCCESS:
      return {
        ...state,
        liveDealData: action.payload.data
      };
    case actionCreators.DEAL_ALARM_ACTIVATE_CHECK: {
      // parseAndShowMessage(
      //   'notice',
      //   'Account Activation Process...',
      //   null,
      //   true
      // ); // pNotify = true
      return { ...state };
    }
    case actionCreators.DEAL_ALARM_SEND_WELCOME_CHECK: {
      parseAndShowMessage('notice', 'Sending Welcome Letter...', null, true); // pNotify = true
      return { ...state };
    }
    case actionCreators.DEAL_ALARM_TRY_TO_SYNC_CHECK: {

      // parseAndShowMessage('notice', 'Trying To Sync An Account...1', null, true); // pNotify = true
      return { ...state, actionButtonDisableStatus: true };
    }
    case actionCreators.DEAL_ALARM_TERMINATE_CHECK: {
      parseAndShowMessage(
        'notice',
        'Terminating Account Process...',
        null,
        true
      ); // pNotify = true
      return { ...state };
    }
    case actionCreators.DEAL_ALARM_GET_LIVE_DATA_CHECK: {
      // parseAndShowMessage(
      //   'notice',
      //   'Refreshing CS And Alarm LIVE Data...',
      //   null,
      //   true
      // ); // pNotify = true
      return { ...state };
    }
    case actionCreators.DEAL_ALARM_OUT_OF_SERVICE_CHECK: {
      // parseAndShowMessage(
      //   'notice',
      //   'Trying To Put CS Out Of Service...',
      //   null,
      //   true
      // ); // pNotify = true
      return { ...state, actionButtonDisableStatus: true };
    }
    case actionCreators.DEAL_ALARM_IN_SERVICE_CHECK: {
      // parseAndShowMessage(
      //   'notice',
      //   'Trying To Put CS In Service...',
      //   null,
      //   true
      // ); // pNotify = true
      return { ...state, actionButtonDisableStatus: true };
    }
    case actionCreators.DEAL_ALARM_CS_UPDATE_CHECK: {
      // parseAndShowMessage('notice', 'Updating CS Information...', null, true); // pNotify = true
      return {
        ...state,
        cs_updated: false
      };
    }
    case actionCreators.DEAL_ALARM_ALARM_UPDATE_CHECK: {
      // parseAndShowMessage(
      //   'notice',
      //   'Updating Alarm Information...',
      //   null,
      //   true
      // ); // pNotify = true
      return {
        ...state,
        alarm_updated: false
      };
    }
    case actionCreators.DEAL_ALARM_OUT_OF_TEST_CHECK: {
      // parseAndShowMessage('notice', 'Out Of Test...', null, true); // pNotify = true
      return { ...state, actionButtonDisableStatus: true };
    }
    case actionCreators.DEAL_ALARM_IMEI_VERIFY_CHECK: {
      parseAndShowMessage('notice', 'Verifying...', null, true); // pNotify = true
      return { ...state };
    }
    // Put here all SUCCESS requests
    case actionCreators.DEAL_ALARM_ACTIVATE_SUCCESS:
    case actionCreators.DEAL_ALARM_SEND_WELCOME_SUCCESS:
    case actionCreators.DEAL_ALARM_TRY_TO_SYNC_SUCCESS:
    case actionCreators.DEAL_ALARM_TRY_TO_UNSYNC_SUCCESS:
    case actionCreators.DEAL_ALARM_IN_SERVICE_SUCCESS:
    case actionCreators.DEAL_ALARM_OUT_OF_SERVICE_SUCCESS:
    case actionCreators.DEAL_ALARM_OUT_OF_TEST_SUCCESS:
    case actionCreators.DEAL_ALARM_TERMINATE_SUCCESS: {
      parseAndShowMessage('success', action.payload);
      return { ...state, actionButtonDisableStatus: false };
    }

    case actionCreators.DEAL_ALARM_TRY_TO_UNSYNC_CHECK: {
      return { ...state, actionButtonDisableStatus: true }
    }

    // Put here all FAIL requests

    // case actionCreators.GET_ZONE_LIST_FAIL:
    case actionCreators.DEAL_ALARM_ACTIVATE_FAIL:
    case actionCreators.DEAL_ALARM_SEND_WELCOME_FAIL:
    case actionCreators.DEAL_ALARM_TRY_TO_SYNC_FAIL:
    case actionCreators.DEAL_ALARM_TRY_TO_UNSYNC_FAIL:
    case actionCreators.DEAL_ALARM_TERMINATE_FAIL:
    case actionCreators.PUSH_ZONE_LIST_FAIL:
    // case actionCreators.DEAL_ALARM_GET_LIVE_DATA_FAIL:
    case actionCreators.DEAL_ALARM_IN_SERVICE_FAIL:
    case actionCreators.DEAL_ALARM_OUT_OF_SERVICE_FAIL:
    // case actionCreators.EVENT_LIST_FAIL:
    case actionCreators.DEAL_ALARM_OUT_OF_TEST_FAIL: {
      parseAndShowMessage('error', action.payload);
      return { ...state, actionButtonDisableStatus: false, loading: false };
    }
    case actionCreators.GET_ZONE_LIST_FAIL: {
      return { ...state, actionButtonDisableStatus: false, loading: false };
    }

    case actionCreators.DEAL_ALARM_IMEI_VERIFY_FAIL:
    case actionCreators.DEAL_ALARM_ALARM_UPDATE_FAIL:
    case actionCreators.GET_DEAL_SELECTED_ITEMS_FAIL:
    case actionCreators.DEAL_ALARM_CS_UPDATE_FAIL: {
      parseAndShowMessage('error', action.payload, null, true); // pNotify = true
      return { ...state };
    }

    case actionCreators.DEAL_ALARM_IMEI_VERIFY_SUCCESS: {
      parseAndShowMessage('success', action.payload, null, true); // pNotify = true
      return { ...state };
    }

    case actionCreators.DEAL_ALARM_ALARM_UPDATE_SUCCESS: {
      parseAndShowMessage('success', action.payload, null, true); // pNotify = true
      return {
        ...state,
        alarm_updated: true
      };
    }

    case actionCreators.DEAL_ALARM_CS_UPDATE_SUCCESS: {
      parseAndShowMessage('success', action.payload, null, true); // pNotify = true
      return {
        ...state,
        cs_updated: true
      };
    }

    default:
      return { ...state };
  }
};
export default newDealReducer;
