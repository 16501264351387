import { commonActionTypes } from 'store/common/commonAction.js';
import * as actionCreators from '../actionTypes';
import { parseAndShowMessage } from 'store/common/common';

const initialState = {
  count: null,
  isLoading: false,
  paymentMethods: [],
  payments: [],
  scheduleList: [],
  contactList: null,
  contactListLoading: false,
  contactListError: null,
  paymentError: null,
  newPaymentMethodId: null,
  successfullyAdded: false,
  brandList: null,
  brandListLoading: false,
  brandListError: null,

  productList: null,
  productListLoading: false,
  productListError: null,
  taxByZip: null,

  profileEditData: null,
  profileEditDataLoading: false,
  profileEditDataError: null,
  pastDue: 0,
  totalRevenue: 0,
  error: null,
  searchTerm: ''
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.GET_PAYMENTS_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        listLoading: true
      };

    case actionCreators.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        listLoading: false,
        payments: action.payload
      };

    case actionCreators.GET_PAYMENTS_FAIL:
      return {
        ...state,
        listLoading: false
      };

    case actionCreators.ADD_PAYMENT_METHOD:
      return {
        ...state,
        listLoading: true,
        paymentError: null,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        submitting: true
      };

    case actionCreators.ADD_PAYMENT_METHOD_SUCCESS:
      parseAndShowMessage(
        'success',
        'Payment method added successfully',
        'Adding New Payment Method',
        true
      );
      return {
        ...state,
        successfullyAdded: true,
        submitting: false,
        newPaymentMethodId: action.payload.id
      };

    case actionCreators.ADD_PAYMENT_METHOD_FAIL:
      parseAndShowMessage(
        'error',
        action.payload,
        'Adding New Payment Method',
        true
      );
      return {
        ...state,
        paymentError: action.payload,
        submitting: false
      };

    case actionCreators.GET_PAYMENT_METHODS_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        listLoading: true
      };

    case actionCreators.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        listLoading: false,
        paymentMethods: action.payload
      };

    case actionCreators.GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        listLoading: false
      };
    // csList
    case commonActionTypes.GET_PAST_DUE_BY_TYPE:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        error: null
      };
    case commonActionTypes.HANDLE_SEARCH:
      return {
        ...state,
        searchTerm: action.payload
      };

    case commonActionTypes.GET_PAST_DUE_BY_TYPE_SUCCESS:
      return {
        ...state,
        pastDue: action.payload,
        error: null
      };

    case commonActionTypes.GET_PAST_DUE_BY_TYPE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case commonActionTypes.GET_TOTAL_BY_TYPE:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        error: null
      };

    case commonActionTypes.GET_TOTAL_BY_TYPE_SUCCESS:
      return {
        ...state,
        totalRevenue: action.payload,
        error: null
      };

    case commonActionTypes.GET_TOTAL_BY_TYPE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case actionCreators.CS_LIST_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: []
      };

    case actionCreators.CS_LIST_SUCCESS:
      return {
        ...state,
        CSList: action.payload.deals_realted_cs,
        unassginedCSNos: action.payload.unassginedCSNos
      };

    case actionCreators.CS_LIST_FAIL:
      return {
        ...state,
        CSList: action.payload.request && action.payload.request.statusText
      };

    case actionCreators.CS_COUNT_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        isLoading: true
      };

    case actionCreators.CS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload,
        isLoading: false
      };

    case actionCreators.CS_COUNT_FAIL:
      return {
        ...state,
        isLoading: false
      };

    case actionCreators.GET_ALLUSERLIST_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        contactListLoading: true
      };

    case actionCreators.GET_ALLUSERLIST_SUCCESS:
      return {
        ...state,
        contactList: action.payload,
        contactListLoading: false
      };

    case actionCreators.GET_ALLUSERLIST_FAIL:
      return {
        ...state,
        contactListError:
          action.payload.request && action.payload.request.statusText,
        contactListLoading: false
      };

    // brand
    case actionCreators.GET_BRANDLIST_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        brandListLoading: true
      };

    case actionCreators.GET_BRANDLIST_SUCCESS:
      return {
        ...state,
        brandList: action.payload,
        brandListLoading: false
      };
    case actionCreators.GET_BRANDLIST_FAIL:
      return {
        ...state,
        brandListError:
          action.payload.request && action.payload.request.statusText,
        brandListLoading: false
      };

    // edit Profile
    case actionCreators.PROFILE_EDIT_CHECK:
      return {
        ...state,
        profileEditDataLoading: true,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        isDelete: false
      };

    case actionCreators.PROFILE_EDIT_SUCCESS:
      return {
        ...state,
        profileEditData: action.payload,
        profileEditDataLoading: false,
        isDelete: false
      };

    case actionCreators.PROFILE_EDIT_FAIL:
      return {
        ...state,
        profileEditDataError:
          action.payload.request && action.payload.request.statusText,
        profileEditDataLoading: false,
        isDelete: false
      };

    // Service UPDATE
    case actionCreators.PROFILE_UPDATE_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        isSubmitted: false
      };

    case actionCreators.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };

    case actionCreators.PROFILE_UPDATE_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    // product
    case actionCreators.GET_PRODUCTLIST_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        productListLoading: true
      };

    case actionCreators.GET_PRODUCTLIST_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        productListLoading: false
      };

    case actionCreators.GET_PRODUCTLIST_FAIL:
      return {
        ...state,
        productListError:
          action.payload.request && action.payload.request.statusText,
        productListLoading: false
      };

    // Delete
    case actionCreators.DELETE_SUCCESS:
      let newState = [...state.scheduleList];
      newState.splice(action.payload, 1);
      return {
        ...state,
        scheduleList: newState
      };

    //techbnician list

    case actionCreators.GET_TECH_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleList: action.payload
      };

    case actionCreators.GET_TECH_SCHEDULE_FAIL:
      return {
        ...state,
        scheduleList: []
      };

    case actionCreators.GET_RELATIONSHIPS_CHECK:
      return {
        ...state,
        paymentMethods: [],
        payments: [],
        scheduleList: [],
        relationShipListLoading: true
      };

    case actionCreators.GET_RELATIONSHIPS_SUCCESS:
      return {
        ...state,
        relationShipList: action.payload,
        relationShipListLoading: false
      };

    case actionCreators.GET_TAX_BY_ZIP_SUCCESS:
      return {
        ...state,
        taxByZip: action.payload.data
      };

    case actionCreators.GET_RELATIONSHIPS_FAIL:
      return {
        ...state,
        relationShipListError:
          action.payload.request && action.payload.request.statusText,
        relationShipListLoading: false
      };
    default:
      return { ...state };
  }
};
export default commonReducer;

// import * as actionCreators from '../actionTypes';

// const initialState = {

// };

// const clientReducer = (state = initialState, action) => {
//   switch (action.type) {

//     case actionCreators.LOGIN_CHECK:
//       return {
//         ...state,

//       };

//     default:
//       return { ...state };
//   }
// };
// export default clientReducer;
