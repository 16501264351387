import newInvoiceFormActions from 'modules/NewInvoice/NewInvoiceForm.actions';

const initialState = {
  list: [],
  data: {},
  creating: false,
  success: false,
  fetching: false,
  deleting: false,
  editing: false,
  canceling: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case newInvoiceFormActions.FETCHING:
      return {
        ...state,
        fetching: true,
        editing: false,
        deleting: false,
        success: false,
        error: null
      };

    case newInvoiceFormActions.CREATING:
      return {
        ...state,
        fetching: true,
        editing: false,
        creating: true,
        deleting: false,
        error: null,
        success: false
      };

    case newInvoiceFormActions.CREATE_SUCCESS:
      return {
        ...state,
        fetching: true,
        data: action.payload,
        creating: false,
        editing: false,
        deleting: false,
        success: true,
        error: null
      };

    case newInvoiceFormActions.FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        success: true,
        error: null
      };

    case newInvoiceFormActions.ERROR:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        success: false,
        error: action.payload
      };

    default:
      return { ...state };
  }
};
export default reducer;
