import { store } from '../store';
import { axios } from 'axios';
import { base_url } from 'env';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PNotify from 'pnotify/dist/es/PNotify';
import momentTimezone from 'moment-timezone';
import moment from 'moment';

export const getToken = () => {
  const token = localStorage.getItem('token');
  return token;
};
export const getAdminToken = () => {
  const adminToken = localStorage.getItem('admin-token');
  return adminToken;
};
export const getHeaders = () => ({
  Authorization: 'Bearer ' + getToken(),
  AdminToken: getAdminToken() ? 'Bearer ' + getAdminToken() : null
});

export const checkRole = allowedList => {
  const user = store.getState().login?.user;
  const roles = user.role_types.client;
  let isAllowed = false;

  if (allowedList && allowedList.length > 0) {
    if (roles.length > 0) {
      isAllowed = !!roles.find(e => allowedList.includes(e));
    }
    return isAllowed;
  }

  // TODO update this everywhere
  if (store.getState().login.user.role_types.client) {
    const isAdmin = store
      .getState()
      .login.user.role_types.client.find(e =>
        ['accounting', 'admin', 'super_admin'].includes(e)
      );
    const isNonAdmin = store
      .getState()
      .login.user.role_types.client.find(e =>
        ['csr', 'technician', 'sales'].includes(e)
      );

    if (isAdmin) {
      return true;
    } else if (isNonAdmin) {
      return false;
    }
  }
};

export const isCSR = () => {
  if (store.getState().login.user.role_types.client) {
    const isNonAdmin = store
      .getState()
      .login.user.role_types.client.find(e => ['csr', 'sales'].includes(e));
    return isNonAdmin ? true : false;
  }
};
export const isSales = () => {
  if (store.getState().login.user.role_types.client) {
    const isSalesRep = store
      .getState()
      .login.user.role_types.client.find(e => ['sales'].includes(e));
    return isSalesRep ? true : false;
  }
};

export const checkIfSpecificClient = client_id => {
  switch (
    store.getState().login.user.role_types.client &&
    store.getState().login.user.client_id
  ) {
    case 169:
    case 172:
    case 210:
    case 273:
    case 278:
    case 340:
    case null:
      return true;
    default:
      return false;
  }
};
export const checkIfClientIsAuthorized = client_ids => {
  const currentClientId = store.getState().login.user.client_id;
  return client_ids.includes(currentClientId) ? true : false;
};

// export const checkWHRole = () =>
//   store.getState().login?.adminData?.role_types?.wh &&
//   store
//     .getState()
//     .login?.adminData?.role_types?.wh.find(e => ['super_admin'].includes(e))
//     ? true
//     : false; // returns true / false

export const userData = () => ({
  client_id: store.getState().login.user.client_id,
  role_id: store.getState().login.user.role_id,
  user_id: store.getState().login.user.id
});

export const fileUploadData = () => {
  const deal_id = JSON.parse(localStorage.getItem('deal_id'));
  return {
    client_id: store.getState().login.user.client_id,
    role_id: store.getState().login.user.role_id,
    user_id: store.getState().login.user.id,
    deal_id: deal_id
  };
};

export const API = () => {
  // products/import
  axios.create({
    baseURL: base_url,
    timeout: 1000,
    headers: { Authorization: 'Bearer ' + getToken() }
  });
};

export const params = () => {
  return {
    client_id: store.getState().login.user.client_id,
    role_id: store.getState().login.user.role_id,
    user_id: store.getState().login.user.id
  };
};

export const utcToTimezone = (utcDateTime, time_zone = null) => {
  return time_zone
    ? moment.utc(utcDateTime).tz(time_zone)
    : moment.utc(utcDateTime).utcOffset(moment().format('Z'));
};

export const timeZoneToUtc = (dateTime) => {
    return moment.utc(dateTime);
};

const MySwal = withReactContent(Swal);

const swalError = (type, message, title = null) => {
  return MySwal.fire({
    // customClass: { container: 'my-swal' },
    // position: 'top-end',
    title: title || (type === 'success' ? 'Success' : 'Error'),
    html: message,
    icon: type,
    type: type
  });
};

// .replace(/_/g, ' ')
//         .toLowerCase()
//         .split(' ')
//         .map(s => s.charAt(0).toUpperCase() + s.substring(1))
//         .join(' ');

const PNotifyError = (type, message, title = null) => {
  switch (type) {
    case 'success':
      return PNotify.success({
        title: title || 'Success',
        text: message
      });
    case 'error':
      return PNotify.error({
        title: title || 'Error',
        text: message
      });
    case 'notice':
      return PNotify.notice({
        title: message || 'Notice',
        icon: 'fas fa-spinner fa-pulse'
      });
    default:
      break;
  }
};

/*

  These notifications are really nice to look at, don't have time to debug and fix styling. Let's use these later on.

*/
// const PNotifyError = (type, message, title = null) => {

//   const Toast = Swal.mixin({
//     toast: true,
//     timerProgressBar: true,
//     position: 'top-end',
//     showConfirmButton: false,
//     timer: 3000,

//   })

//   switch (type) {
//     case 'success':
//       return Toast.fire(`${title || "Success"}`, `${message || ""}`, 'success')

//     case 'error':
//       return Toast.fire(`${title || "Error"}`, `${message || ""}`, 'error')

//     case 'notice':
//       return Toast.fire(`${title || "Notice"}`, `${message || ""}`, 'info')

//     default:
//       break;
//   }

// }

// Error from whole object from catch of axios call
export const parseAndShowMessage = (
  type,
  data,
  title = null,
  pNotify = false
) => {
  let message = 'Undefined';
  switch (type) {
    case 'error':
      message = 'Something went wrong';
      if (data?.request) {
        if (data?.request?.responseText[0] !== '<') {
          message = JSON.parse(data?.request?.responseText)?.message;
        } else if (data?.request?.status == 404) {
          message = 'Not Found';
        }
      }
      break;

    case 'success':
      message = 'Success';
      if (data) {
        if (data.statusText) {
          message = data.statusText;
        } else if (data.data && typeof data.data === 'string') {
          message = data.data;
        }
      }
      break;

    case 'notice':
      break;
    default:
      break;
  }

  if (typeof data === 'string') {
    message = data;
  }

  if (pNotify) {
    return PNotifyError(type, message, title);
  } else {
    return swalError(type, message, title);
  }
};
