import companyActions from '../../modules/Company/Company.actions';

const initialState = {
  list: [],
  data: null,
  fetching: false,
  deleting: false,
  editing: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case companyActions.FTCHING:
      return {
        ...state,
        list: [],
        data: null,
        fetching: true,
        editing: false,
        deleting: false,
        error: null
      };

    case companyActions.FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    case companyActions.FETCH_ONE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    case companyActions.ERROR:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        error: action.payload
      };

    case companyActions.DELETING:
      return {
        ...state,
        deleting: true,
        error: null
      };
    case companyActions.DELETE_SUCCESS:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };
    case companyActions.UPDATING:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: true,
        deleting: false,
        error: null
      };
    case companyActions.UPDATING_SUCCESS:
      return {
        ...state,
        data: action.payload,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    default:
      return { ...state };
  }
};
export default reducer;
