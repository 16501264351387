import axios from 'axios';
import { base_url } from 'env';
import { getHeaders, parseAndShowMessage } from 'store/common/common';
import history from 'store/history';
import qs from 'qs';

axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'TASK';

const taskActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  UPDATING: `${prefix}_UPDATING`,
  CREATING: `${prefix}_CREATING`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_STATUSES_SUCCESS: `${prefix}_FETCH_STATUSES_SUCCESS`,
  DELETING: `${prefix}_DELETING`,
  SUBMIT_SIGNATURE: `${prefix}_SUBMIT_PAYMENT`,
  SUBMIT_SIGNATURE_SUCCESS: `${prefix}_SUBMIT_SIGNATURE_SUCCESS`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  UPDATING_SUCCESS: `${prefix}_UPDATING_SUCCESS`,
  UPDATING_SUMMARY_SUCCESS: `${prefix}_UPDATING_SUMMARY_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  create: data => dispatch => {
    dispatch({ type: taskActions.CREATING });

    axios
      .post('tasks', data)
      .then(response => {
        dispatch({
          type: taskActions.CREATE_SUCCESS,
          payload: response.data
        });
        parseAndShowMessage(
          'success',
          'Task Created Successfully',
          'Create Task'
        );
        setTimeout(() => {
          history.push(`/tasks/${response.data.id}`);
        }, 2000);
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Create Task');
        dispatch({ type: taskActions.ERROR, payload: error });
      });
  },
  getStatuses: () => dispatch => {
    dispatch({ type: taskActions.FETCHING });

    axios
      .get(base_url + 'tasks/statuses')
      .then(response => {
        dispatch({
          type: taskActions.FETCH_STATUSES_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({
          type: taskActions.ERROR,
          payload: error
        })
      );
  },
  getAll: (params = null) => dispatch => {
    dispatch({ type: taskActions.FETCHING });

    axios
      .get('tasks', {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params);
        }
      })
      .then(response =>
        dispatch({
          type: taskActions.FETCH_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: taskActions.ERROR, payload: error }));
  },
  getById: id => dispatch => {
    dispatch({ type: taskActions.FETCHING });

    axios
      .get(`resources/task/${id}`)
      .then(response =>
        dispatch({
          type: taskActions.FETCH_ONE_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: taskActions.ERROR, payload: error }));
  },
  getByModel: (model, modelId) => dispatch => {
    dispatch({ type: taskActions.FETCHING });

    axios
      .get(`event/${model}/${modelId}`)
      .then(response =>
        dispatch({
          type: taskActions.SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: taskActions.ERROR, payload: error }));
  },
  delete: id => dispatch => {
    dispatch({ type: taskActions.DELETING });

    axios
      .delete(`event/${id}`)
      .then(response =>
        dispatch({
          type: taskActions.DELETE_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: taskActions.ERROR, payload: error }));
  },
  updateStatus: (id, status) => dispatch => {
    dispatch({ type: taskActions.UPDATING });

    axios
      .post(`tasks/${id}/status`, {
        status_id: status.id
      })
      .then(response => {
        parseAndShowMessage(
          'success',
          `Task was successfully updated ${status.name}`,
          'Status Update'
        );
        dispatch({
          type: taskActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Status Update');
        dispatch({ type: taskActions.ERROR, payload: error });
      });
  },
  updatePriority: (id, priority) => dispatch => {
    dispatch({ type: taskActions.UPDATING });

    axios
      .post(`tasks/${id}/priority`, {
        priority
      })
      .then(response => {
        parseAndShowMessage(
          'success',
          `Task was successfully updated ${priority}`,
          'Status Update'
        );
        dispatch({
          type: taskActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Status Update');
        dispatch({ type: taskActions.ERROR, payload: error });
      });
  },
  update: (id, data) => async dispatch => {
    dispatch({ type: taskActions.UPDATING });

    await axios
      .put(`tasks/${id}`, data)
      .then(response => {
        parseAndShowMessage('success', response, 'Task Update');
        dispatch({
          type: taskActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Task Update');
        dispatch({ type: taskActions.ERROR, payload: error });
      });
  }
};

export default taskActions;
