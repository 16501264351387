import * as actionCreators from '../actionTypes';

const initialState = {
  inventoryList:[],
  deleteMessage:null,
  isDelete:false,

  listloading:false,
  listerror:null,

  inventoryList:null,
  inventoryListLoading:false,
  inventoryListError:null,

  technicianList:null,
  technicianListLoading:false,
  technicianListError:null,

  fetchinventoryList:null,
  fetchinventoryListLoading:false,
  fetchinventoryListError:null,

  submitMessage:null,
  isSubmitted:false,


  selectedinventory:null,
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {

    // Delete Inventory
    case actionCreators.INVENTORY_DELETE_CHECK:
      return{
        ...state,
        isDelete:false
      }
      case actionCreators.INVENTORY_DELETE_SUCCESS:
      return{
        ...state,
        isDelete:true,
        deleteMessage: action.payload,
      }
      case actionCreators.INVENTORY_DELETE_FAIL:
      return{
        ...state,
        isDelete:false
      }

      // Inventory List

    case actionCreators.GET_INVENTORYLIST_CHECK:
      return{
        ...state,
        inventoryListLoading:true,
      }
    
    case actionCreators.GET_INVENTORYLIST_SUCCESS:
      return{
        ...state,
        inventoryList:action.payload,
        inventoryListLoading:false,
      }
    case actionCreators.GET_INVENTORYLIST_FAIL:
    return{
      ...state,
      inventoryListError:action.payload.request && action.payload.request.statusText,
      inventoryListLoading:false,
    }

     // fetchInventory List

     case actionCreators.GET_FETCHINVENTORYLIST_CHECK:
     return{
       ...state,
       fetchinventoryListLoading:true,
     }
   
   case actionCreators.GET_FETCHINVENTORYLIST_SUCCESS:
     return{
       ...state,
       fetchinventoryList:action.payload,
       fetchinventoryListLoading:false,
     }
   case actionCreators.GET_FETCHINVENTORYLIST_FAIL:
   return{
     ...state,
     fetchinventoryListError:action.payload.request && action.payload.request.statusText,
     fetchinventoryListLoading:false,
   }

    // Inventory ADD
    case actionCreators.INVENTORY_ADD_CHECK:
      return{
        ...state,
        isSubmitted:false
      }
      case actionCreators.INVENTORY_ADD_SUCCESS:
      return{
        ...state,
        isSubmitted:true,
        submitMessage: action.payload,
      }
      case actionCreators.INVENTORY_ADD_FAIL:
      return{
        ...state,
        isSubmitted:false
      }

      // Inventory UPDATE
    case actionCreators.INVENTORY_UPDATE_CHECK:
    return{
      ...state,
      isSubmitted:false
    }
    case actionCreators.INVENTORY_UPDATE_SUCCESS:
    return{
      ...state,
      isSubmitted:true,
      submitMessage: action.payload,
    }
    case actionCreators.INVENTORY_UPDATE_FAIL:
    return{
      ...state,
      isSubmitted:false
    }

     // Inventory Edit
     case actionCreators.FETCHINVENTORY_EDIT_CHECK:
     return{
       ...state,
       isSubmitted:false
     }
     case actionCreators.FETCHINVENTORY_EDIT_SUCCESS:
     return{
       ...state,
       isSubmitted:true,
       selectedinventory: action.payload,
     }
     case actionCreators.FETCHINVENTORY_EDIT_FAIL:
     return{
       ...state,
       isSubmitted:false
     }
 

      // Technician List

      case actionCreators.GET_TECHNICIANLIST_CHECK:
      return{
        ...state,
        technicianListLoading:true,
      }
    
    case actionCreators.GET_TECHNICIANLIST_SUCCESS:
      return{
        ...state,
        technicianList:action.payload,
        technicianListLoading:false,
      }
    case actionCreators.GET_TECHNICIANLIST_FAIL:
    return{
      ...state,
      technicianListError:action.payload.request && action.payload.request.statusText,
      technicianListLoading:false,
    }

    default:
      return { ...state };
  }

  
};
export default inventoryReducer;




// import * as actionCreators from '../actionTypes';

// const initialState = {

// };

// const clientReducer = (state = initialState, action) => {
//   switch (action.type) {
   

//     case actionCreators.LOGIN_CHECK:
//       return {
//         ...state,
      
//       };
    
//     default:
//       return { ...state };
//   }
// };
// export default clientReducer;
