import axios from 'axios';
import { base_url } from 'env';
import { getHeaders, parseAndShowMessage } from 'store/common/common';
import history from 'store/history';

axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'NEW_INVOICE_FORM';

const newInvoiceFormActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  CREATING: `${prefix}_CREATING`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  create: data => dispatch => {
    dispatch({ type: newInvoiceFormActions.CREATING });
    axios
      .post(base_url + 'invisible-deal', data)
      .then(response => {
        dispatch({
          type: newInvoiceFormActions.CREATE_SUCCESS,
          payload: response.data
        });
        parseAndShowMessage(
          'success',
          'New Invoice Created Successfully',
          'Create New Invoice'
        );
        setTimeout(() => {
          history.push(`/invoice/${response.data.id}`);
        }, 2000);
      })
      .catch(error => {
        dispatch({ type: newInvoiceFormActions.ERROR, payload: error });
      });
  },
};

export default newInvoiceFormActions;
