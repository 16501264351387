import * as actionCreators from '../actionTypes';

const initialState = {
  bgCheckTypes: [],
  bgStatus: [],
  prevChecks: [],
  bgCheck: [],
  fetching: false,
  bgError: null
};

const bgReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.GET_BG_STATUS:
      return {
        ...state,
        bgCheckTypes: [],
        bgStatus: [],
        prevChecks: [],
        bgCheck: [],
        fetching: true,
        bgError: null
      };

    case actionCreators.GET_BG_STATUS_SUCCESS:
      return {
        ...state,
        bgStatus: action.payload,
        bgError: null,
        fetching: false
      };

    case actionCreators.GET_BG_STATUS_FAIL:
      return {
        ...state,
        bgError: action.payload.request && action.payload.request.statusText
      };

    case actionCreators.GET_PREV_BG_TYPES:
      return {
        ...state,
        fetching: true,
        bgError: null
      };

    case actionCreators.GET_PREV_BG_TYPES_SUCCESS:
      return {
        ...state,

        prevChecks: action.payload,
        bgError: null,
        fetching: false
      };

    case actionCreators.GET_PREV_BG_TYPES_FAIL:
      return {
        ...state,
        bgError: action.payload.request && action.payload.request.statusText
      };

    case actionCreators.GET_BG_TYPES:
      return {
        ...state,
        bgCheckTypes: [],
        bgStatus: [],
        prevChecks: [],
        bgCheck: [],
        fetching: true,
        bgError: null
      };

    case actionCreators.GET_BG_TYPES_SUCCESS:
      return {
        ...state,
        bgCheckTypes: action.payload,
        bgError: null,
        fetching: false
      };

    case actionCreators.GET_BG_TYPES_FAIL:
      return {
        ...state,
        bgError: action.payload.request && action.payload.request.statusText
      };

    case actionCreators.RUN_BG_CHECK:
      return {
        ...state,
        bgCheckTypes: [],
        bgStatus: [],
        prevChecks: [],
        bgCheck: [],
        fetching: true,
        bgError: null
      };

    case actionCreators.RUN_BG_CHECK_SUCCESS:
      return {
        ...state,
        bgCheck: action.payload,
        bgError: null,
        fetching: false
      };

    case actionCreators.RUN_BG_CHECK_FAIL:
      return {
        ...state,
        fetching: false,
        bgError: action.payload
      };

    default:
      return { ...state };
  }
};
export default bgReducer;
