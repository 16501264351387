import axios from 'axios';
import { base_url } from 'env';
import { getHeaders, parseAndShowMessage } from 'store/common/common';
import { store } from 'store/store';
import Geocode from 'react-geocode';
import moment from 'moment';

import qs from 'qs';

Geocode.setApiKey('AIzaSyADo-Lidh5uCF392j79_-pooszeTD83mj4');

axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    options.paramsSerializer = function(params) {
      return qs.stringify(params);
    };
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'EVENT_STATUS';

const eventStatusActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  FETCH_ALL_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  DELETING: `${prefix}_DELETING`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  UPDATING: `${prefix}_UPDATING`,
  UPDATING_SUCCESS: `${prefix}_UPDATING_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  getAll: (params = null) => dispatch => {
    dispatch({ type: eventStatusActions.FETCHING });

    axios
      .post(`${base_url}event/getAll`, {
        params: params
      })
      .then(response =>
        dispatch({
          type: eventStatusActions.FETCH_ALL_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: eventStatusActions.ERROR, payload: error })
      );
  },
  getById: id => dispatch => {
    dispatch({ type: eventStatusActions.FETCHING });

    axios
      .get(`${base_url}event/${id}`)
      .then(res =>
        dispatch({
          type: eventStatusActions.FETCH_ONE_SUCCESS,
          payload: res.data
        })
      )
      .catch(error =>
        dispatch({ type: eventStatusActions.ERROR, payload: error })
      );
  },
  getByModel: (model, modelId) => dispatch => {
    dispatch({ type: eventStatusActions.FETCHING });

    axios
      .get(`${base_url}event/${model}/${modelId}/statuses`)
      .then(response =>
        dispatch({
          type: eventStatusActions.FETCH_ALL_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: eventStatusActions.ERROR, payload: error })
      );
  },
  editStatus: (id, status) => async dispatch => {
    dispatch({ type: eventStatusActions.DELETING });

   await axios
      .post(base_url + `event/${id}/status`, status)
      .then(response => {
        parseAndShowMessage(
          'success',
          `You have changed the status to ${status.name}`,
          'Status Update'
        );
        dispatch({
          type: eventStatusActions.DELETE_SUCCESS,
          payload: response.data
        });
        return response.data
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Status Update');
        dispatch({ type: eventStatusActions.ERROR, payload: error });
      });
  },
  delete: id => async dispatch => {
    dispatch({ type: eventStatusActions.DELETING });

   await axios
      .delete(`${base_url}event/${id}`)
      .then(response => {
        parseAndShowMessage('success', response, 'Delete Event');
        dispatch({
          type: eventStatusActions.DELETE_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Delete Event');
        dispatch({ type: eventStatusActions.ERROR, payload: error });
      });
  },
  update: (newTime, statusId) => async dispatch => {
    dispatch({ type: eventStatusActions.UPDATING });

  return await axios
      .put(base_url + `event/statuses/${statusId}`, {
        checked_at: newTime
      })
      .then(response => {
        parseAndShowMessage('success', response, 'Update Event', true);
        dispatch({
          type: eventStatusActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Update Event');
        dispatch({ type: eventStatusActions.ERROR, payload: error });
      });
  }
};

export default eventStatusActions;
