import * as actionCreators from '../actionTypes';
import { parseAndShowMessage } from '../../store/common/common';

const initialState = {
  listloading: false,
  listerror: null,
  deleteMessage: null,
  createSettingData: null,
  isDelete: false,
  clientList: [],
  clientData: {},
  whPackages: [],
  settingsForModelByName: [],
  clientEditData: [],
  taxSettings: {
    installation: false,
    equipment: false,
    accessories: false,
    product: false
  },
  centralStationTemplates: [],
  centralStationDispatches: [],
  centralStations: null
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.CLIENT_LIST_CHECK:
      return {
        ...state,
        listloading: true,
        clientList: [],
        clientData: {},
        whPackages: [],
        settingsForModelByName: [],
        clientEditData: [],
        centralStationTemplates: [],
        centralStationDispatches: [],
        isDelete: false
      };

    case actionCreators.CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clientList: action.payload,
        isDelete: false,
        listloading: false
      };

    case actionCreators.CLIENT_CENTRAL_STATION_TEMPLATES_SUCCESS:
      return {
        ...state,
        centralStationTemplates: action.payload.data
      };

    case actionCreators.CENTRAL_STATION_DISPATCHES_SUCCESS:
      return {
        ...state,
        centralStationDispatches: action.payload.data
      };

    case actionCreators.CLIENT_CENTRAL_STATION_SUCCESS:
      return {
        ...state,
        centralStations: action.payload.data
      };
    case actionCreators.GET_SETTING_FOR_MODEL_BY_NAME_SUCCESS:
      return {
        ...state,
        settingsForModelByName: {
          ...state.settingsForModelByName,
          [action.forModel]: {
            ...state.settingsForModelByName[action.forModel],
            [action.settingName]: action.payload.data
          }
        }
      };
    case actionCreators.CLIENT_LIST_FAIL:
      return {
        ...state,
        listerror: action.payload.request && action.payload.request.statusText,
        isDelete: false,
        listloading: false
      };

    case actionCreators.EDIT_TAX_SETTING:
      return {
        ...state,
        taxSettings: {
          ...state.taxSettings,
          [action.payload.name]: action.payload.value
        }
      };

    case actionCreators.CLIENT_DELETE_CHECK:
      return {
        ...state,
        clientList: [],
        clientData: {},
        whPackages: [],
        settingsForModelByName: [],
        clientEditData: [],
        centralStationTemplates: [],
        centralStationDispatches: [],
        isDelete: false
      };
    case actionCreators.CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        isDelete: true,
        deleteMessage: action.payload
      };
    case actionCreators.CLIENT_DELETE_FAIL:
      return {
        ...state,
        isDelete: false
      };

    case actionCreators.STEP_1_SUBMIT:
      const data = action.payload;
      return {
        ...state,
        clientData: { ...data }
      };

    case actionCreators.STEP_2_SUBMIT:
      const data2 = action.payload;
      return {
        ...state,
        clientData: { ...state.clientData, ...data2 }
      };

    case actionCreators.STEP_3_SUBMIT:
      const data3 = action.payload;
      return {
        ...state,
        clientData: { ...state.clientData, ...data3 }
      };

    case actionCreators.STEP_4_SUBMIT:
      const data4 = action.payload;
      return {
        ...state,
        clientData: { ...state.clientData, ...data4 }
      };

    case actionCreators.STEP_5_SUBMIT:
      const data5 = action.payload;
      return {
        ...state,
        clientData: { ...state.clientData, ...data5 }
      };

    case actionCreators.STEP_6_SUBMIT:
      const data6 = action.payload;
      return {
        ...state,
        clientData: { ...state.clientData, ...data6 }
      };
    case actionCreators.STEP_7_SUBMIT:
      const data7 = action.payload;
      return {
        ...state,
        clientData: { ...state.clientData, ...data7 }
      };
    // client add
    case actionCreators.CLIENT_ADD_CHECK:
      return {
        ...state,
        isSubmitted: false
      };
    case actionCreators.CLIENT_ADD_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.CLIENT_ADD_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    case actionCreators.CLIENT_EDIT_CHECK:
      return {
        ...state
      };
    case actionCreators.CLIENT_EDIT_SUCCESS:
      return {
        ...state,
        clientEditData: action.payload
      };
    case actionCreators.CLIENT_EDIT_FAIL:
      return {
        ...state
      };
    case actionCreators.WH_PACKAGE_CHECK:
      return {
        ...state
      };
    case actionCreators.WH_PACKAGE_SUCCESS:
      return {
        ...state,
        whPackages: action.payload
      };
    case actionCreators.WH_PACKAGE_FAIL:
      return {
        ...state,
        whPackagesError:
          action.payload.request && action.payload.request.statusText
      };
    case actionCreators.SETTINGS_BY_TYPE_CHECK:
      return {
        ...state
      };
    case actionCreators.SETTINGS_BY_TYPE_SUCCESS:
      return {
        ...state,
        settingsByType: action.payload.data
      };
    case actionCreators.SETTINGS_CHECK:
      return {
        ...state
      };
    case actionCreators.SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload.data
      };
    case actionCreators.SAVE_SETTINGS_CHECK:
      return {
        ...state
      };
    case actionCreators.CLIENT_CREATE_CHECK:
      return {
        ...state
      };
    case actionCreators.CREATE_SETTING_CHECK:
      return {
        ...state
      };

    // Put here all SUCCESS requests
    case actionCreators.CREATE_SETTING_SUCCESS: {
      parseAndShowMessage('success', action.payload);
      return {
        ...state,
        createSettingData: action.payload.data
      };
    }
    case actionCreators.CLIENT_CENTRAL_STATION_TEMPLATES_UPDATE_SUCCESS:
    case actionCreators.SAVE_SETTINGS_SUCCESS:
    case actionCreators.CLIENT_CREATE_SUCCESS: {
      parseAndShowMessage('success', action.payload);
      return { ...state };
    }

    // Put here all FAIL requests
    case actionCreators.CLIENT_CENTRAL_STATION_TEMPLATES_UPDATE_FAIL:
    case actionCreators.SAVE_SETTINGS_FAIL:
    case actionCreators.SETTINGS_FAIL:
    case actionCreators.SETTINGS_BY_TYPE_FAIL:
    case actionCreators.CLIENT_CREATE_FAIL:
    case actionCreators.SETTING_TYPES_FAIL:
    case actionCreators.CREATE_SETTING_FAIL:
    case actionCreators.CLIENT_CENTRAL_STATION_FAIL: {
      parseAndShowMessage('error', action.payload);
      return { ...state };
    }

    default:
      return { ...state };
  }
};
export default clientReducer;

// import * as actionCreators from '../actionTypes';

// const initialState = {

// };

// const clientReducer = (state = initialState, action) => {
//   switch (action.type) {

//     case actionCreators.LOGIN_CHECK:
//       return {
//         ...state,

//       };

//     default:
//       return { ...state };
//   }
// };
// export default clientReducer;
