export const lookupTable = {
    '/deleted_entities': 'deleted entities',
    '/alarm_connections': 'alarm connections',
    '/import/create': 'import create',
    '/payment_tokens': 'payment tokens',
    '/leads': 'leads',
    '/leads/create': 'leads create',
    '/leads/:id': 'leads',
    '/leads/edit/:id': 'leadsedit',
    '/template/create': 'template create',
    '/docs/index': 'docs index',
    '/docs/view/:id': 'docsview',
    '/consumerFinance': 'consumerFinance',
    '/consumerFinance/estimator': 'consumerFinance estimator',
    '/consumerFinanceOverview': 'consumerFinanceOverview',
    '/property-tax-calculator': 'property tax calculator',
    '/system-actions': 'System Actions',
    '/dashboard': 'Dashboard',
    '/centralStationID': 'Central Station',
    '/client/manageclient': 'Manage Client',
    '/editClient': 'Edit Client',
    '/user/adduser': 'Add User',
    '/users': 'Users',
    '/user/:id': 'User',
    '/calendar': 'Calendar',
    '/maps/dispatch-map/': 'Dispatch Map',
    '/dispatch': 'Dispatch',
    '/products/create': 'Products Create',
    '/products/import': 'Products Import',
    '/products': 'Products',
    '/tasks': 'Tasks',
    '/tasks/:id': 'Tasks',
    '/task/create': 'Create Task',
    '/product/Product/:id': 'Product',
    '/announcements': 'Announcements',
    '/site/add': 'Add Site',
    '/site/manage': 'Manage Site',
    '/sites/editsite/:id': 'Site',
    '/subscriptions/manage': 'Manage Subscriptions',
    '/subscriptions': 'Subscriptions',
    '/subscriptions/:id': 'Subscriptions',
    '/payments': 'Payments',
    '/transactions': 'transactions',
    '/createPayments': 'Create Payments',
    '/customerPayment': 'Customer Payment',
    '/payments/:id': 'Payment',
    '/contact/add': 'Add Contact',
    '/contact/manage': 'Manage Contacts',
    '/contact/:id': 'contact',
    '/company/list': 'Company List',
    '/company/:id': 'Company',
    '/recurring/list': 'Recurring List',
    '/recurring/create': 'Recurring Create',
    '/recurring/:id': 'recurring',
    '/inventory/addinventory': 'inventory addinventory',
    '/inventory/manageinventory': 'inventory manageinventory',
    '/inventory/updateinventory/:id': 'inventory update',
    '/daily_batch': 'Daily Batch',
    '/sales_tax': 'Sales Tax',
    '/merchant_report': 'Merchant Report',
    '/merchant_report/transactions': 'Merchant Report Transactions',
    '/gl_report': 'GL Report',
    '/aging': 'aging',
    '/deposit_report': 'Deposit Report',
    '/equipment_report': 'Equipment Report',
    '/deals': 'Deals',
    '/deals/audit': 'Deals Audit',
    '/Deals/one_time': 'Deals One Time',
    '/Deals/nap': 'Deals Nap',
    '/Deals/fire': 'Deals Fire',
    '/Deals/networking': 'Deals Networking',
    '/Deals/newdeal/:id': 'New Deal',
    '/Deals/alarminfo': 'Deals alarm Info',
    '/Deals/alarminfotwo': 'Deals alarm info two',
    '/Deals/finaldeal': 'Deals final deal',
    '/Deals/automationinfo': 'Deals automation info',
    '/Deals/automationinfostep2': 'Deals automation info step 2',
    '/Deals/cctvinfo': 'Deals cctvinfo',
    '/Deals/cctvinfosteptwo': 'Deals cctv info step two',
    '/Deals/accesscontrol': 'Deals accesscontrol',
    '/Deals/accesscontrolstep2': 'Deals accesscontrolstep2',
    '/Deals/alarm': 'Deals alarm',
    '/Deals/access': 'Deals access',
    '/Deals/cctv': 'Deals CCTV',
    '/Deals/automation': 'Deals Automation',
    '/deals/managedeals': 'Manage Deals',
    '/deals/overview/:id/': 'Deals Overview',
    '/vehicles': 'Vehicles',
    '/vehicle/:id': 'Vehicle',
    '/vehicle/create': 'Vehicle Create',
    '/Deals/addservice/': 'Deals Add Service',
    '/Deals/editservice/:id': 'Service Edit',
    '/setting': 'Settings',
    '/companyinfo': 'Company Info',
    '/stateLicense': 'State License',
    '/taxInfo': 'Tax Info',
    '/settings/:action/:for': 'Settings',
    '/setting/:settingName/:action/:for': 'Setting',
    '/settings/:for/:id': 'Settings',
    '/eAgreements': 'eAgreements',
    '/merchServices': 'merchServices',
    '/whPackages': 'whPackages',
    '/centralStations': 'centralStations',
    '/communcations': 'communcations',
    '/gCal': 'gCal',
    '/dealerPrograms': 'Dealer Programs',
    '/gsuite': 'Google Suite',
    '/integrations': 'Integrations',
    '/defaultProducts': 'Default Products',
    '/packages': 'Packages',
    '/service/': 'Service',
    '/serviceCall/create': ' create Service Call',
    '/serviceCall/:id': 'Service Call Details',
    '/service/editservice/:id': 'Edit Service',
    '/reports': 'Reports',
    '/reports/background-check': 'Reports background-check',
    '/reports/payment-breakdown': 'Reports payment-breakdown',
    '/reports/average-rmr': 'Reports average-rmr',
    '/reports/deals': 'Reports Deals',
    '/search': 'Search',
    '/invoice/:id': 'Invoice Details',
    '/statement/:model/:modelId': 'Statement',
    '/pastDueList': 'Past Due List',
    '/invoiceList': 'Invoice List',
    '/manageInvoices': 'Manage Invoices',
    '/estimate/:id': 'Estimate Details',
    '/estimates': 'Estimates',
    '/subscription/create': 'Create Subscription',
  }
