import * as actionCreators from '../actionTypes';

const initialState = {
  invoices: [],

  per_page: 10,
  page: 1,
  total_rows: 0,
  reset_default_page: false,

  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        total_rows: action.payload.total,
        per_page: action.payload.per_page
      };
    case actionCreators.FETCH_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return { ...state };
  }
};
