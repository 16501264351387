// import { lazy } from 'react';
//
//  const lazyRetry = (componentImport) => {
//
//     return new Promise((resolve, reject) => {
//
//         const hasRefreshed = JSON.parse(
//             window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
//         );
//
//         componentImport().then((component) => {
//             window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
//             resolve(component);
//         }).catch((error) => {
//             console.log('Lazy Error', error)
//             if (!hasRefreshed) { // not been refreshed yet
//                 console.log('Lazy Error !hasRefreshed', error)
//                 window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
//                 return window.location.reload(); // refresh the page
//             }
//             reject(error);
//         });
//     });
// };
// const YourPage = React.lazy(()=> lazyRetry(() => import('./pages/YourPage')))
// export const lazyWithRetry = (componentImport) => lazy(() => lazyRetry(componentImport));
// export const lazyWithRetry = (componentImport) => lazy(() => componentImport());



export function lazyWithRetry(
    componentImport,
    chunkIdentifier
) {
    const sessionStorageKey = `retry-lazy-refreshed-${chunkIdentifier}`;

    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed due to this chunk
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(sessionStorageKey) || "false"
        );

        // try to import the component
        componentImport()
            .then((component) => {
                // success so reset the refresh state
                window.sessionStorage.setItem(sessionStorageKey, "false");
                resolve(component);
            })
            .catch((error) => {
                console.log('Lazy Error', error)
                if (!hasRefreshed) {
                    console.log('Lazy Error !hasRefreshed', error)
                    // not been refreshed yet
                    window.sessionStorage.setItem(sessionStorageKey, "true");
                    // refresh the page
                    return window.location.reload();
                }

                // Default error behaviour as already tried refresh
                reject(error);
            });
    });
}

