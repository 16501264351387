import * as actionCreators from '../actionTypes';
import _  from 'lodash';

const initialState = {
	communicationsPackagesList: [],
	commPackagesAddons: [],
	dealTemplates: [],
	fetching: false,
	pkgError: null,
	internalPackageList: [
		{ id: 1, package_name: 'Gold', rate: 75, term: 48 },
		{ id: 2, package_name: 'Silver', rate: 60, term: 24 },
		{ id: 3, package_name: 'Bronze', rate: 35, term: 72 },
	],
	selectedPackage: {
		package_name: 'Custom',
		id: _.uniqueId(),
		term: 24,
		rate: 0,
	},
};

const pkgReducer = (state = initialState, action) => {
	switch (action.type) {
		// GET COMM PACKAGES
		case actionCreators.GET_COMM_PACKAGES:
			return {
				...state,
				fetching: true,
			};
		case actionCreators.GET_COMM_PACKAGES_SUCCESS:
			return {
				...state,
				fetching: false,
				pkgError: null,
				communicationsPackagesList: action.payload,
			};
		case actionCreators.GET_COMM_PACKAGES_FAIL:
			return {
				...state,
				pkgError: action.payload.request && action.payload.request.statusText,
			};

		// GET COMM PACKAGE ADDONS
		case actionCreators.GET_COMM_PACKAGE_ADDONS:
			return {
				...state,
				fetching: true,
			};
		case actionCreators.GET_COMM_PACKAGE_ADDONS_SUCCESS:
			return {
				...state,
				fetching: false,
				pkgError: null,
				commPackagesAddons: action.payload,
			};
		case actionCreators.GET_COMM_PACKAGE_ADDONS_FAIL:
			return {
				...state,
				commPackagesAddonsError: action.payload.request && action.payload.request.statusText,
			};

			case actionCreators.SELECT_PACKAGE:
			return {
				...state,
				selectedPackage: action.payload,
				// selected: true,
			};
			case actionCreators.UPDATE_PACKAGE:
			return {
				...state,
				...state.selectedPackage, selectedPackage: action.payload,
				// selected: true,
			};

		default:
			return { ...state };
	}
};
export default pkgReducer;
