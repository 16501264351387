import * as actionCreators from '../actionTypes';

const initialState = {
  brandList: [
    {
      id: 0,
      name: '2gig',
      // img: gig,
      total: 0
    },
    {
      id: 1,
      name: 'Alula',
      // img: alula,
      total: 0
    },
    {
      id: 2,
      name: 'Interlogix',
      // img: interlogix,
      total: 0
    },
    {
      id: 3,
      name: 'DMP',
      // img: dmp,
      total: 0
    },
    {
      id: 4,
      name: 'dsc',
      // img: dsc,
      total: 0
    },
    {
      id: 5,
      name: 'Fire Lite',
      // img: firelite,
      total: 0
    },
    {
      id: 6,
      name: 'Honeywell',
      // img: honeywell,
      total: 0
    },
    {
      id: 7,
      name: 'Qolsys',
      // img: qolsys,
      total: 0
    },
    {
      id: 7,
      name: 'Silent knight',
      // img: knight,
      total: 0
    }
  ],
  brandsProductList: [],
  fetching: false,
  error: null
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.BRANDLIST_CLIENT_CHECK:
      return {
        ...state,
        brandsProductList: [],
        fetching: true
      };
    case actionCreators.BRANDLIST_CLIENT_CHECK_SUCCESS:
      return {
        ...state,
        fetching: false,
        brandList: action.payload
      };
    case actionCreators.BRANDLIST_CLIENT_CHECK_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload.request && action.payload.request.statusText
      };
    // BRANDS PRODUCT LIST
    case actionCreators.PRODUCTLIST_BRAND_CHECK:
      return {
        ...state,
        brandsProductList: [],
        fetching: true
      };
    case actionCreators.PRODUCTLIST_BRAND_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        fetching: false,
        brandsProductList: action.payload
      };
    case actionCreators.PRODUCTLIST_BRAND_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload.request && action.payload.request.statusText
      };
    default:
      return { ...state };
  }
};
export default brandReducer;
