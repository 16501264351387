import React from 'react';
import {createRoot} from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import App from './App/index';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import rtl from 'jss-rtl';
import { createTheme } from 'theme';
import { create } from 'jss';
import { store, persistor } from './store/store';
import config from './config';
import history from './store/history';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'reactQuery/queryClient';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LicenseInfo } from '@material-ui/x-grid';
import * as serviceWorker from './serviceWorker';
LicenseInfo.setLicenseKey(
  '586f8549dd34d610c4e1514f46695c6eT1JERVI6MjUwNTQsRVhQSVJZPTE2NTMwNzA0NDkwMDAsS0VZVkVSU0lPTj0x'
);

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createTheme();

const root = createRoot(document.getElementById('root'));

const app = (
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <SnackbarProvider
            dense
            maxSnack={3}
          >
            <QueryClientProvider client={queryClient}>
              <Router
                basename={config.basename}
                forceRefresh={true}
                history={history}
              >
                <App />
              </Router>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

root.render(app);

serviceWorker.unregister();
