import * as actionCreators from '../actionTypes';

const initialState = {
  showPaymentModal: false
};

const newPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.HANDLE_NEW_PAYMENT_MODAL:
      return {
        ...state,
        show: !state.showPaymentModal
      };
    default:
      return { ...state };
  }
};

export default newPaymentReducer;
