import contactActions from 'modules/Contact/Contact.actions';

const initialState = {
  list: [],
  paymentMethods: [],
  newPaymentMethodId: null,
  data: {},
  creating: false,
  success: false,
  fetching: false,
  deleting: false,
  editing: false,
  error: null,
  successfullyAdded: false,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case contactActions.FETCHING:
      return {
        ...state,
        fetching: true,
        data: {},
        paymentMethods: [],
        editing: false,
        successfullyAdded: false,
        deleting: false,
        error: null
      };

    case contactActions.FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        editing: false,
        successfullyAdded: false,
        deleting: false,
        error: null
      };

    case contactActions.FETCH_ONE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        editing: false,
        successfullyAdded: false,
        deleting: false,
        error: null
      };

    case contactActions.ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        successfullyAdded: true,
        submitting: false,
        newPaymentMethodId: action.payload.id
      };
    case contactActions.FETCHING_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: [],
        loading: true,
      }
    case contactActions.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        successfullyAdded: false,
        error: null,
        loading: false,
        paymentMethods: action.payload
      };

    case contactActions.GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        successfullyAdded: false,
        error: action.payload
      };

    case contactActions.ERROR:
      return {
        ...state,
        fetching: false,
        editing: false,
        successfullyAdded: false,
        deleting: false,
        error: action.payload
      };

    case contactActions.DELETING:
      return {
        ...state,
        deleting: true,
        successfullyAdded: false,
        error: null
      };
    case contactActions.DELETE_SUCCESS:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        deleting: false,
        successfullyAdded: false,
        error: null
      };
    case contactActions.UPDATING:
      return {
        ...state,
        paymentMethods: [],
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: true,
        successfullyAdded: false,
        deleting: false,
        error: null
      };
    case contactActions.UPDATING_SUCCESS:
      return {
        ...state,
        data: action.payload,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        successfullyAdded: false,
        deleting: false,
        error: null
      };

    default:
      return { ...state };
  }
};
export default reducer;
