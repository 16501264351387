import axios from 'axios';
import { base_url } from '../../env';
import { store } from '../../store/store';
import { getHeaders, parseAndShowMessage } from '../../store/common/common';

import Geocode from 'react-geocode';
import qs from 'qs';

Geocode.setApiKey('AIzaSyADo-Lidh5uCF392j79_-pooszeTD83mj4');

axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    options.paramsSerializer = function(params) {
      return qs.stringify(params);
    };
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'COMPANY';

const companyActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  FETCH_ALL_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  DELETING: `${prefix}_DELETING`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  UPDATING: `${prefix}_UPDATING`,
  UPDATING_SUCCESS: `${prefix}_UPDATING_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  getAll: (params = null) => dispatch => {
    dispatch({ type: companyActions.FETCHING });
    let query = {
      model: 'Company',
      queries: [
        {
          field: 'client_id',
          value: store.getState().login.user.client_id
        }
      ]
    };
    axios
      .get(base_url + 'resources/company', {
        params: params
      })
      .then(response =>
        dispatch({
          type: companyActions.FETCH_ALL_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: companyActions.ERROR, payload: error }));
  },
  getById: id => dispatch => {
    dispatch({ type: companyActions.FETCHING });

    let query = {
      model: 'Site',
      queries: [{ field: 'id', value: id }]
    };

    axios
      .get(`${base_url}resources/company/${id}`)
      .then(res => {
        Geocode.fromAddress(
          `${res.data.address} ${res.data.city} ${res.data.state} ${res.data.zip}`
        ).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            dispatch({
              type: companyActions.FETCH_ONE_SUCCESS,
              payload: { ...res.data, lat, lng }
            });
          },
          error => {
            console.error(error);
          }
        );
      })
      .catch(error => dispatch({ type: companyActions.ERROR, payload: error }));
  },
  getByModel: (model, modelId) => dispatch => {
    dispatch({ type: companyActions.FETCHING });

    axios
      .get(base_url + `company/${model}/${modelId}`)
      .then(response =>
        dispatch({
          type: companyActions.SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: companyActions.ERROR, payload: error }));
  },
  delete: id => dispatch => {
    dispatch({ type: companyActions.DELETING });

    axios
      .delete(base_url + `company/${id}`)
      .then(response =>
        dispatch({
          type: companyActions.DELETE_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: companyActions.ERROR, payload: error }));
  },
  update: (id, data) => dispatch => {
    dispatch({ type: companyActions.UPDATING });

    axios
      .put(base_url + `company/${id}`, data)
      .then(response => {
        parseAndShowMessage('success', response, 'Company Update');
        dispatch({
          type: companyActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Company Update');
        dispatch({ type: companyActions.ERROR, payload: error });
      });
  }
};

export default companyActions;
