import * as actionCreators from '../actionTypes';
import Axios from 'axios';
import { base_url } from '../../env';
import { store } from '../store';
import { getHeaders } from '../common/common';
import { parseAndShowMessage } from 'store/common/common';
import Geocode from 'react-geocode';
import qs from 'qs';
import history from '../history';

export const getComponentsToShow = id => {
  return dispatch => {
    dispatch({ type: actionCreators.GET_DEAL_COMPONENTS_TO_SHOW_CHECK });

    Axios.get(base_url + `deal/${id}/componentsToShow`, {
      headers: getHeaders()
    })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_DEAL_COMPONENTS_TO_SHOW_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.GET_DEAL_COMPONENTS_TO_SHOW_FAIL,
          payload: error
        })
      );
  };
};

export const getPayments = dealId => {
  return dispatch => {
    dispatch({ type: actionCreators.GET_DEAL_PAYMENTS_CHECK });

    const data = {
      deal_id: dealId
    };

    Axios.post(base_url + 'payments/deal', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_DEAL_PAYMENTS_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.GET_DEAL_PAYMENTS_FAIL,
          payload: error
        })
      );
  };
};

// GET BRANDS BY CLIENT
export const getBrandByClient = () => {
  return dispatch => {
    dispatch({ type: actionCreators.BRANDLIST_CLIENT_CHECK });

    Axios.get(base_url + 'brand/byClientId', {
      headers: getHeaders()
    })
      .then(res => {
        return dispatch({
          type: actionCreators.BRANDLIST_CLIENT_CHECK_SUCCESS,
          payload: res.data.data
        });
      })
      .catch(err =>
        dispatch({
          type: actionCreators.BRANDLIST_CLIENT_CHECK_FAIL,
          payload: err
        })
      );
  };
};

// GET PRODUCTS BY BRAND
export const getProductByBrand = id => {
  const data = { brandId: id };

  return dispatch => {
    dispatch({ type: actionCreators.PRODUCTLIST_BRAND_CHECK });

    Axios.post(base_url + 'product/getByBrandId', data, {
      headers: getHeaders()
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => console.log(err));
  };
};

// GET DEAL LIST
export const dealSystemsList = (params = null) => {
  const path = params?.custom_path ?? 'deal-system/get-by-customer';

  return dispatch => {
    dispatch({ type: actionCreators.DEAL_SYSTEM_LIST_CHECK });

    Axios.get(
      base_url + path,
      {
        headers: getHeaders(),
        params: {
          ...params
        },
        paramsSerializer: function (params) {
          return qs.stringify(params);
        }
      }
    )
      .then(response => {
        return dispatch({
          type: actionCreators.DEAL_SYSTEM_LIST_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.DEAL_SYSTEM_LIST_FAIL, payload: error })
      );
  };
};

// GET DEAL LIST
export const dealList = (params = null) => {
  const path = params.custom_path ?? 'deals';

  return dispatch => {
    dispatch({ type: actionCreators.DEAL_LIST_CHECK });

    Axios.post(
      base_url + path,
      {},
      {
        headers: getHeaders(),
        params: {
          ...params,
          filter: params
            ? {
              ...params.filter,
              ['stage_of_sale']: {
                ['>']: 2
              }
            }
            : {}
        },
        paramsSerializer: function (params) {
          return qs.stringify(params);
        }
      }
    )
      .then(response => {
        return dispatch({
          type: actionCreators.DEAL_LIST_SUCCESS,
          payload: response.data
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.DEAL_LIST_FAIL, payload: error })
      );
  };
};
// GET Event LIST
export const getEventList = (id, clearCache = false) => {
  return dispatch => {
    dispatch({ type: actionCreators.EVENT_LIST_CHECK });

    const data = {
      dealId: id,
      clearCache: clearCache
    };
    Axios.post(base_url + 'deal/getHistory', data, { headers: getHeaders() })
      .then(response =>
        dispatch({
          type: actionCreators.EVENT_LIST_SUCCESS,
          payload: response
        })
      )
      .catch(error =>
        dispatch({
          type: actionCreators.EVENT_LIST_FAIL,
          payload: error
        })
      );
  };
};

// GET SERVICE LIST
export const serviceList1 = id => {
  return dispatch => {
    dispatch({ type: actionCreators.SERVICE_LIST_CHECK });

    const data = {
      user_id: store.getState().login.user.id,
      client_id: store.getState().login.user.client_id,
      role_id: store.getState().login.user.role_id,
      deal_id: id
    };
    Axios.post(base_url + 'services', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.SERVICE_LIST_SUCCESS,
          payload: response.data.data.services
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.SERVICE_LIST_FAIL, payload: error })
      );
  };
};

// GET TEST CATEGORIES LIST
export const getTestCategoriesList = () => {
  return dispatch => {
    dispatch({ type: actionCreators.GET_TEST_CATEGORIES });

    Axios.get(base_url + 'deal/getTestCategories', {
      headers: getHeaders()
    })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_TEST_CATEGORIES_SUCCESS,
          payload: response
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.GET_TEST_CATEGORIES_FAIL,
          payload: error
        })
      );
  };
};

// SUBMIT PASSCODE
export const submitPasscode = data => {
  return async dispatch => {
    dispatch({ type: actionCreators.CHECK_PASSCODE });

    await Axios.post(base_url + 'deal/passcodeTest', data, {
      headers: getHeaders()
    })
      .then(response => {
        return dispatch({
          type: actionCreators.CHECK_PASSCODE_SUCCESS,
          payload: response
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.CHECK_PASSCODE_FAIL, payload: error })
      );
  };
};

// SUBMIT ON TEST
export const submitOnTest = data => {
  return dispatch => {
    dispatch({ type: actionCreators.SUBMIT_TEST_DETAILS });

    Axios.post(base_url + 'deal/onTest', data, {
      headers: getHeaders()
    })
      .then(response => {
        dispatch({
          type: actionCreators.SUBMIT_TEST_DETAILS_SUCCESS,
          payload: response
        });
        dispatch(getDealOverviewDetails(data.dealId));
      })
      .catch(error =>
        dispatch({
          type: actionCreators.SUBMIT_TEST_DETAILS_FAIL,
          payload: error
        })
      );
  };
};

export const outOfTest = data => {
  return dispatch => {
    dispatch({ type: actionCreators.DEAL_ALARM_OUT_OF_TEST_CHECK });

    Axios.post(base_url + 'deal/clearTest', data, {
      headers: getHeaders()
    })
      .then(response => {
        dispatch({
          type: actionCreators.DEAL_ALARM_OUT_OF_TEST_SUCCESS,
          payload: response
        });
        dispatch(getDealOverviewDetails(data.dealId));
      })
      .catch(error =>
        dispatch({
          type: actionCreators.DEAL_ALARM_OUT_OF_TEST_FAIL,
          payload: error
        })
      );
  };
};

export const deleteDealElement = id => {
  return async dispatch => {
    dispatch({ type: actionCreators.DEAL_DELETE_CHECK });

    const data = { id: id };

    await Axios.post(base_url + 'deals/delete', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.DEAL_DELETE_SUCCESS,
          payload: response.data.data.deal
        });
      })
      .catch(error =>
        dispatch({ type: actionCreators.DEAL_DELETE_FAIL, payload: error })
      );
  };
};

const getSelectedCS = () => {
  return window.axios
    .get('centralStation')
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log('error', error);
      return [];
    });
};

export const getDealOverviewDetails = id => {
  return async dispatch => {
    dispatch({ type: actionCreators.OVERVIEW_DETAILS_CHECK });
    let lat, lng;

    // const data = { id: id };
    let cList;

    getSelectedCS()
      .then(response => {
        cList = response.data;
      })
      .catch(error => {
        console.log('error', error);
        cList = [];
      });

    await Axios.get(base_url + `deals/${id}`, { headers: getHeaders() })
      .then(response => {
        Geocode.fromAddress(
          `${response.data.site.full_address}` ||
          `${response.data.site.address}`
        )
          .then(res => {
            lat = res.results[0]?.geometry?.location?.lat ?? '';
            lng = res.results[0]?.geometry?.location?.lng ?? '';
          })
          .catch(err => console.log(err))
          .then(() => {
            dispatch({
              type: actionCreators.OVERVIEW_DETAILS_SUCCESS,
              payload: {
                ...response.data,
                cList,
                site: {
                  ...response.data.site,
                  latitude: lat,
                  longitude: lng
                }
              }
            });
          })
          .catch(err => console.log(err));
      })
      .catch(error =>
        dispatch({ type: actionCreators.OVERVIEW_DETAILS_FAIL, payload: error })
      );
  };
};

export const getEditDetails = id => {
  return dispatch => {
    dispatch({ type: actionCreators.EDITDEAL_CHECK });

    const data = {
      deal_id: id,
      // "deal_type":store.getState().login.user.role_id,
      user_id: store.getState().login.user.id,
      role_id: store.getState().login.user.role_id,
      client_id: store.getState().login.user.client_id
    };
    Axios.post(base_url + 'deals/edit', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.EDITDEAL_SUCCESS,
          payload: response.data.data
        });
      })
      // .then(res => {
      //   const id = res.payload.contact_data.id;
      //   const pData = { contact_id: id };
      //   dispatch({ type: actionCreators.GET_PAYMENT_METHOD });
      //   Axios.post(base_url + 'payment-method', pData, {
      //     headers: getHeaders()
      //   })
      //     .then(response => {
      //       return dispatch({
      //         type: actionCreators.GET_PAYMENT_METHOD_SUCCESS,
      //         payload: response.data
      //       });
      //     })
      //     .catch(error =>
      //       dispatch({
      //         type: actionCreators.GET_PAYMENT_METHOD_FAIL,
      //         payload: error
      //       })
      //     );

      // })
      .catch(error =>
        dispatch({ type: actionCreators.EDITDEAL_FAIL, payload: error })
      );
  };
};

//new deal contacts

export const dealsContactList = () => {
  return async dispatch => {
    dispatch({ type: actionCreators.GET_DEALS_CONTACT_LIST_CHECK });
    const data = {
      user_id: store.getState().login.user.id,
      client_id: store.getState().login.user.client_id,
      role_id: store.getState().login.user.role_id
    };
    Axios.post(base_url + 'deals/create', data, { headers: getHeaders() })
      .then(response => {
        return dispatch({
          type: actionCreators.GET_DEALS_CONTACT_LIST_SUCCESS,
          payload: response.data.data.contacts
        });
      })
      .catch(error =>
        dispatch({
          type: actionCreators.GET_DEALS_CONTACT_LIST_FAIL,
          payload: 'ERROR'
        })
      );
  };
};

//new deals

export const newDeal = (deal_type, deal_type_id) => {
  return dispatch => {
    dispatch({ type: actionCreators.EDITDEAL_CHECK });

    const submitData = {
      stage_of_sale: 1,
      deal_type: deal_type,
      user_id: store.getState().login.user.id,
      role_id: store.getState().login.user.role_id,
      client_id: store.getState().login.user.client_id

      // deal_type: 'alarm',
      // deal_step_number: 1,
      // client_id: 163,
      // user_id: 225,
      // company_id: 0,
      // fname: 'Sam',
      // lname: 'Elder',
      // address: '186 Via Catalunha',
      // county: 'Palm Beach County',
      // country: 'US',
      // state: 'FL',
      // city: 'Jupiter',
      // zip: 33458,
      // phone: 9376846629,
      // cell_phone: 9376846629,
      // email: 'sam.elder@saap.us',
      // dob: '04/09/1999',
      // ssn_no: 1231231231231232,
      // contact_type: 'new',
      // address_type: 'residential',
      // address_premise: '186 Via Catalunha',
      // city_premise: 'Jupiter',
    };

    // Axios.post(base_url + 'deals/store', submitData, {
    //   headers: getHeaders()
    // })
    //   .then(response => {
    //     dispatch({ type: actionCreators.DEAL_STAGE_1_CHECK });

    // localStorage.setItem('deal_id', null);

    //     dispatch({
    //       type: actionCreators.DEAL_STAGE_1_SUCCESS,
    //       payload: response.data.data
    //     });
    const state = {
      deal_screen_id: deal_type_id,
      deal_screen_type: deal_type
    };
    // if(deal_type_id ==1){
    //     history.push('/deals/newdeal/1',state);
    // }else if(deal_type_id == 2){
    //     history.push('/deals/newdeal/2',state);
    // }else if(deal_type_id == 3){
    //     history.push('/deals/newdeal/3',state);
    // }else{
    //     history.push('/deals/newdeal/4',state)
    // }

    if (deal_type == 'alarm') {
      history.push('/deals/alarm', state);
    } else if (deal_type == 'automation') {
      history.push('/deals/automation', state);
    } else if (deal_type == 'cctv') {
      history.push('/deals/cctv', state);
    } else if (deal_type == 'access_control') {
      history.push('/deals/access', state);
    } else if (deal_type === 'one_time') {
      history.push('/deals/one_time', state);
    } else if (deal_type === 'nap') {
      history.push('/deals/nap', state);
    } else if (deal_type === 'fire') {
      history.push('/deals/fire', state);
    } else if (deal_type === 'networking') {
      history.push('/deals/networking', state);
    } else if (deal_type === 'pers') {
      history.push('/deals/pers', state);
    } else {
      history.push('/deals');
    }
    // })
    // .catch(
    //   // error=>console.log(error)
    //   error =>
    //     dispatch({ type: actionCreators.DEAL_STAGE_1_FAIL, payload: error })
    // );
  };
};
// before stepper
// export const newDeal = (deal_type,deal_type_id) => {

//     return dispatch=>{
//         dispatch({type:actionCreators.EDITDEAL_CHECK});

//         const submitData ={
//             "stage_of_sale":1,
//             "deal_type":deal_type,
//             "client_id":store.getState().login.user.client_id,
//             "user_id":store.getState().login.user.id,
//         };

//         Axios.post(base_url+'deals/store',submitData,{headers:getHeaders()}).then(
//             response=>{
//                 dispatch({type:actionCreators.DEAL_STAGE_1_CHECK})

//                 if(response.data.success === true){

//                     localStorage.setItem("deal_id",response.data.data.deal_id);

//                     dispatch({
//                         type:actionCreators.DEAL_STAGE_1_SUCCESS,
//                         payload:response.data.data
//                     })
//                     const state = {
//                         deal_screen_id:deal_type_id,
//                         deal_screen_type:deal_type
//                     }
//                     if(deal_type_id ==1){
//                         history.push('/deals/newdeal/1',state);
//                     }else if(deal_type_id == 2){
//                         history.push('/deals/newdeal/2',state);
//                     }else if(deal_type_id == 3){
//                         history.push('/deals/newdeal/3',state);
//                     }else{
//                         history.push('/deals/newdeal/4',state)
//                     }
//                 }else{
//                     return dispatch({
//                         type:actionCreators.DEAL_STAGE_1_FAIL,
//                         payload:'REPONSE ERROR'
//                     })
//                 }
//             }
//             ).catch(
//                 // error=>console.log(error)
//                 error=> dispatch({type:actionCreators.DEAL_STAGE_1_FAIL,payload:error})
//             )
//        }
// }

export const newDealStage = (deal_type, stage, data, deal_type_id, props) => {
  return async dispatch => {
    const submitData = {
      ...data,
      stage_of_sale: stage,
      deal_type: deal_type,
      client_id: store.getState().login.user.client_id,
      // deal_id: deal_id,
      user_id: store.getState().login.user.id,
      role_id: store.getState().login.user.role_id
    };

    const deal_id = JSON.parse(localStorage.getItem('deal_id'));
    if (!(deal_id == null || deal_id == undefined || deal_id == '')) {
      submitData.deal_id = deal_id;
    }

    const types = {
      stage: stage,
      deal_type: deal_type,
      ...data
    };

    dispatch({
      type: actionCreators.SET_TYPE_AND_STAGE,
      payload: types
    });

    await Axios.post(base_url + 'deals/store', submitData, {
      headers: getHeaders()
    })
      .then(response => {
        dispatch({ type: actionCreators.DEAL_STAGE_2_CHECK });

        dispatch({
          type: actionCreators.DEAL_STAGE_2_SUCCESS,
          payload: response.data.data
        });

        localStorage.setItem('deal_id', response.data.data);

        props.onNext();
      })
      .catch(error => {
        dispatch({
          type: actionCreators.DEAL_STAGE_2_FAIL,
          payload: error
        });
      });
  };
};

export const newDealStageTwo = (
  deal_type,
  stage,
  data,
  deal_type_id,
  props
) => {
  const prevStep = JSON.parse(localStorage.getItem('dealData'));
  const nextStep = { ...prevStep, ...data };

  localStorage.setItem('dealData', JSON.stringify(nextStep));
  return async dispatch => {
    const deal_id = localStorage.getItem('deal_id');

    // const deal_id=store.getState().deal.newDeal.deal_id;

    if (deal_id == null || deal_id == undefined || deal_id == '') {
      history.push('/deals');
    }
    const newSubData = {
      stage_of_sale: stage,
      deal_type: deal_type,
      client_id: store.getState().login.user.client_id,
      deal_id: deal_id,
      user_id: store.getState().login.user.id,
      role_id: store.getState().login.user.role_id
      // term: data.selectedPackage.term,
      // rmr: data.selectedPackage.rate,
      // centralStationId: data.central_station,
      // centralStationAddOnIds:
      //   data.selectedAddons.length &&
      //   data.selectedAddons.data.map(a => a.csAddon.id),
      // communicationId:
      //   data.selectedCommunication.length &&
      //   data.selectedCommunication.map(c => c.id).pop(),
      // communicationPackageId:
      //   data.selectedCommunicationPkgs.length &&
      //   data.selectedCommunicationPkgs.map(p => p.id).pop(),
      // communicationAddOnIds:
      //   data.selectedCommPkgAddons &&
      //   data.selectedCommPkgAddons.data.map(a => a.value),
      // equipments: data.equipments,
      // activationFee: data.activationFee,
      // installationFee: data.installFee
    };
    // const submitData = {
    //   ...data,
    //   stage_of_sale: stage,
    //   deal_type: deal_type,
    //   client_id: store.getState().login.user.client_id,
    //   deal_id: deal_id,
    //   user_id: store.getState().login.user.id,
    //   role_id: store.getState().login.user.role_id
    // };
    let submitData;

    switch (deal_type) {
      case 'alarm':
        submitData = {
          stage_of_sale: stage,
          deal_type: deal_type,
          client_id: store.getState().login.user.client_id,
          deal_id: deal_id,
          user_id: store.getState().login.user.id,
          role_id: store.getState().login.user.role_id,
          term: data.selectedPackage.term,
          rmr: data.selectedPackage.rate,
          centralStationId: data.central_station,
          // receiverName: data.selected_receiver_group
          //   ? data.selected_receiver_group.pop() &&
          //     data.selected_receiver_group.pop().label
          //   : '',
          centralStationAddOnIds:
            data.selectedAddons && data.selectedAddons.length
              ? data.selectedAddons.map(a => a.csAddon.id)
              : null,
          communicationId:
            data.selectedCommunication?.id ?? null,
          communicationPackageId:
            data.selectedCommunicationPkgs &&
              data.selectedCommunicationPkgs.value
              ? data.selectedCommunicationPkgs.value
              : null,
          communicationAddOnIds:
            data.selectedCommPkgAddons && data.selectedCommPkgAddons.length
              ? data.selectedCommPkgAddons.map(a => a.value)
              : null,
          equipments: data.equipments,
          activationFee: data.activationFee,
          installationFee: data.installFee
        };
        break;
      case 'nap':
        submitData = {
          stage_of_sale: stage,
          deal_type: deal_type,
          client_id: store.getState().login.user.client_id,
          deal_id: deal_id,
          user_id: store.getState().login.user.id,
          role_id: store.getState().login.user.role_id,
          term: data.selectedPackage.term,
          // receiverName: data.selected_receiver_group
          //   ? data.selected_receiver_group.pop() &&
          //     data.selected_receiver_group.pop().label
          //   : '',
          rmr: data.selectedPackage.rate,
          centralStationId: data.central_station,
          centralStationAddOnIds:
            data.selectedAddons && data.selectedAddons.length
              ? data.selectedAddons.map(a => a.csAddon.id)
              : null,
          communicationId:
            data.selectedCommunication && data.selectedCommunication.length
              ? data.selectedCommunication.map(c => c.id).pop()
              : null,
          communicationPackageId:
            data.selectedCommunicationPkgs &&
              data.selectedCommunicationPkgs.value
              ? data.selectedCommunicationPkgs.value
              : null,
          communicationAddOnIds:
            data.selectedCommPkgAddons && data.selectedCommPkgAddons.length
              ? data.selectedCommPkgAddons.map(a => a.value)
              : null,
          equipments: data.equipments,
          activationFee: data.activationFee,
          installationFee: data.installFee
        };
        break;
      case 'fire':
        submitData = {
          stage_of_sale: stage,
          deal_type: deal_type,
          client_id: store.getState().login.user.client_id,
          deal_id: deal_id,
          user_id: store.getState().login.user.id,
          role_id: store.getState().login.user.role_id,
          term: data.selectedPackage.term,
          rmr: data.selectedPackage.rate,
          // receiverName: data.selected_receiver_group
          //   ? data.selected_receiver_group.pop() &&
          //     data.selected_receiver_group.pop().label
          //   : '',
          centralStationId: data.central_station,
          centralStationAddOnIds:
            data.selectedAddons && data.selectedAddons.length
              ? data.selectedAddons.map(a => a.csAddon.id)
              : null,
          communicationId:
            data.selectedCommunication && data.selectedCommunication.length
              ? data.selectedCommunication.map(c => c.id).pop()
              : null,
          communicationPackageId:
            data.selectedCommunicationPkgs &&
              data.selectedCommunicationPkgs.value
              ? data.selectedCommunicationPkgs.value
              : null,
          communicationAddOnIds:
            data.selectedCommPkgAddons && data.selectedCommPkgAddons.length
              ? data.selectedCommPkgAddons.map(a => a.value)
              : null,
          equipments: data.equipments,
          activationFee: data.activationFee,
          installationFee: data.installFee
        };
        break;
      case 'one_time':
        submitData = {
          stage_of_sale: stage,
          deal_type: deal_type,
          client_id: store.getState().login.user.client_id,
          deal_id: deal_id,
          user_id: store.getState().login.user.id,
          role_id: store.getState().login.user.role_id,
          equipments: data.equipments,
          // receiverName: data.selected_receiver_group
          //   ? data.selected_receiver_group.pop().label
          //   : '',
          activationFee: data.activationFee,
          installationFee: data.installFee,
          term: data.term,
          rmr: data.rmr
        };
        break;
      case 'cctv':
        submitData = {
          stage_of_sale: stage,
          deal_type: deal_type,
          client_id: store.getState().login.user.client_id,
          deal_id: deal_id,
          user_id: store.getState().login.user.id,
          role_id: store.getState().login.user.role_id,
          // receiverName: data.selected_receiver_group
          //   ? data.selected_receiver_group.pop() &&
          //     data.selected_receiver_group.pop().label
          //   : '',
          equipments: data.equipments,
          number_of_cameras: data.camTotal,
          activationFee: data.activationFee,
          installationFee: data.installFee,
          term: data.term,
          rmr: data.rmr
        };
        break;
      case 'networking':
        submitData = {
          stage_of_sale: stage,
          deal_type: deal_type,
          client_id: store.getState().login.user.client_id,
          deal_id: deal_id,
          user_id: store.getState().login.user.id,
          role_id: store.getState().login.user.role_id,
          equipments: data.equipments,
          number_of_cameras: data.camTotal,
          // receiverName: data.selected_receiver_group
          //   ? data.selected_receiver_group.pop() &&
          //     data.selected_receiver_group.pop().label
          //   : '',
          activationFee: data.activationFee,
          installationFee: data.installFee,
          term: data.term,
          rmr: data.rmr
        };
        break;
      case 'access_control':
        submitData = {
          stage_of_sale: stage,
          deal_type: deal_type,
          client_id: store.getState().login.user.client_id,
          deal_id: deal_id,
          user_id: store.getState().login.user.id,
          role_id: store.getState().login.user.role_id,
          equipments: data.equipments,
          // receiverName: data.selected_receiver_group
          //   ? data.selected_receiver_group.pop().label
          //   : '',
          number_of_doors: data.doorTotal,
          activationFee: data.activationFee,
          installationFee: data.installFee,
          term: data.term,
          rmr: data.rmr
        };
        break;
      default:
        break;
    }

    const types = {
      stage: stage,
      deal_type: deal_type,
      ...data
    };

    dispatch({
      type: actionCreators.SET_TYPE_AND_STAGE,
      payload: types
    });

    await Axios.post(base_url + 'deals/store', submitData, {
      headers: getHeaders()
    })
      .then(response => {
        dispatch({ type: actionCreators.DEAL_STAGE_3_CHECK });

        dispatch({
          type: actionCreators.DEAL_STAGE_3_SUCCESS,
          payload: response.data.data
        });
        const state = {
          deal_screen_id: deal_type_id,
          deal_screen_type: deal_type
        };
        // if(deal_type == "alarm"){
        //     history.push('/deals/alarminfotwo',state);
        // }else if(deal_type == "automation"){
        //     history.push('/deals/automationinfostep2',state);
        // }else if(deal_type == "cctv"){
        //     history.push('/deals/cctvinfosteptwo',state);
        // }else if(deal_type == "access_control"){
        //     history.push('/deals/accesscontrolstep2',state)
        // }else{
        //     history.push('/deals')
        // }
        props.onNext();
      })
      .catch(error =>
        dispatch({ type: actionCreators.DEAL_STAGE_3_FAIL, payload: error })
      );
  };
};

export const newDealStageThree = (deal_type, stage, data, props) => {
  // const prevStep = JSON.parse(localStorage.getItem('dealData'));
  // const nextStep = { ...prevStep, ...data };
  // localStorage.setItem('dealData', JSON.stringify(nextStep));

  return async dispatch => {
    const deal_id = localStorage.getItem('deal_id');

    // const deal_id=store.getState().deal.newDeal.deal_id;

    if (deal_id == null || deal_id == undefined || deal_id == '') {
      history.push('/deals');
    }

    const submitData = {
      ...data,
      stage_of_sale: stage,
      deal_type: deal_type,
      client_id: store.getState().login.user.client_id,
      deal_id: deal_id,
      update_sub_deal_id: store.getState().deal.updated_deal_id,
      user_id: store.getState().login.user.id,
      role_id: store.getState().login.user.role_id
    };

    const types = {
      stage: stage,
      deal_type: deal_type,
      ...data
    };

    dispatch({
      type: actionCreators.SET_TYPE_AND_STAGE,
      payload: types
    });

    await Axios.post(base_url + 'deals/store', submitData, {
      headers: getHeaders()
    })
      .then(response => {
        dispatch({ type: actionCreators.DEAL_STAGE_4_CHECK });

        dispatch({
          type: actionCreators.DEAL_STAGE_4_SUCCESS,
          payload: response.data.data
        });
        // const state = {
        //   deal_screen_id: deal_type_id,
        //   deal_screen_type: deal_type
        // };

        // history.push('/deals/finaldeal',state)
        props.onNext();
      })
      .catch(error =>
        dispatch({ type: actionCreators.DEAL_STAGE_4_FAIL, payload: error })
      );
  };
};

export const newDealStageFinal = (deal_type, stage, data, deal_type_id) => {
  return async dispatch => {
    const deal_id = localStorage.getItem('deal_id');

    // const deal_id=store.getState().deal.newDeal.deal_id;

    if (deal_id == null || deal_id == undefined || deal_id == '') {
      history.push('/deals');
    }

    const submitData = {
      ...data,
      stage_of_sale: 5,
      deal_type: deal_type,
      client_id: store.getState().login.user.client_id,
      deal_id: deal_id,
      update_payment_details: store.getState().deal.updated_deal_id,
      user_id: store.getState().login.user.id,
      role_id: store.getState().login.user.role_id
    };

    const types = {
      stage: stage,
      deal_type: deal_type,
      ...data
    };

    dispatch({
      type: actionCreators.SET_TYPE_AND_STAGE,
      payload: types
    });

    await Axios.post(base_url + 'deals/store', submitData, {
      headers: getHeaders()
    })
      .then(response => {
        dispatch({ type: actionCreators.DEAL_STAGE_5_CHECK });

        dispatch({
          type: actionCreators.DEAL_STAGE_5_SUCCESS,
          payload: response.data.data
        });
        const state = {
          deal_screen_id: deal_type_id,
          deal_screen_type: deal_type
        };
        history.push(`/deals/overview/${deal_id}`);
      })
      .catch(error =>
        dispatch({ type: actionCreators.DEAL_STAGE_5_FAIL, payload: error })
      );
  };
};

export const getPaymentMethods = data => dispatch => {
  dispatch({ type: actionCreators.GET_PAYMENT_METHOD });
  Axios.post(base_url + 'payment-method', data, { headers: getHeaders() })
    .then(response => {
      return dispatch({
        type: actionCreators.GET_PAYMENT_METHOD_SUCCESS,
        payload: response.data.data
      });
    })
    .catch(error =>
      dispatch({
        type: actionCreators.GET_PAYMENT_METHOD_FAIL,
        payload: error
      })
    );
};

// export const getSubscriptions = data => dispatch => {
//   dispatch({ type: actionCreators.GET_SUBSCRIPTIONS });
//   Axios.post(
//     base_url + '/subscriptions',
//     { deal_id: data },
//     { headers: getHeaders() }
//   )
//     .then(response => {
//       console.log(response);
//       return dispatch({
//         type: actionCreators.GET_SUBSCRIPTIONS_SUCCESS,
//         payload: response.data.data
//       });
//     })
//     .catch(error =>
//       dispatch({
//         type: actionCreators.GET_SUBSCRIPTIONS_FAIL,
//         payload: error
//       })
//     );
// };

export const getCentralStations = () => async dispatch => {
  dispatch({ type: actionCreators.GET_CENTRAL_STATIONS });
  await Axios.get(base_url + 'centralStation/deal', { headers: getHeaders() })
    .then(response => {
      return dispatch({
        type: actionCreators.GET_CENTRAL_STATIONS_SUCCESS,
        payload: response.data.data
      });
    })
    .catch(error =>
      dispatch({
        type: actionCreators.GET_CENTRAL_STATIONS_FAIL,
        payload: error
      })
    );
};

export const getCentralStationAddons = id => dispatch => {
  const data = {
    centralStationId: id
  };
  dispatch({ type: actionCreators.GET_CENTRAL_STATION_ADDONS });
  Axios.post(base_url + 'centralStation/addOns/get', data, {
    headers: getHeaders()
  })
    .then(response => {
      return dispatch({
        type: actionCreators.GET_CENTRAL_STATION_ADDONS_SUCCESS,
        payload: response.data.data
      });
    })
    .catch(error =>
      dispatch({
        type: actionCreators.GET_CENTRAL_STATION_ADDONS_FAIL,
        payload: error
      })
    );
};

export const getDealTemplates = () => dispatch => {
  dispatch({ type: actionCreators.GET_DEAL_TEMPLATES });
  Axios.get(base_url + 'deal/template', {
    headers: getHeaders()
  })
    .then(response =>
      dispatch({
        type: actionCreators.GET_DEAL_TEMPLATES_SUCCESS,
        payload: response.data.data
      })
    )
    .catch(error =>
      dispatch({
        type: actionCreators.GET_DEAL_TEMPLATES_FAIL,
        payload: error
      })
    );
};

export const getDealType = () => dispatch => {
  dispatch({ type: actionCreators.GET_DEAL_TYPE });
  Axios.get(base_url + 'dealType', {
    headers: getHeaders()
  })
    .then(response =>
      dispatch({
        type: actionCreators.GET_DEAL_TYPE_SUCCESS,
        payload: response.data.data
      })
    )
    .catch(error =>
      dispatch({
        type: actionCreators.GET_DEAL_TYPE_FAIL,
        payload: error
      })
    );
};

export const getZoneList = (id, params = null) => dispatch => {
  dispatch({ type: actionCreators.GET_ZONE_LIST });
  Axios.get(base_url + `deal/${id}/getZoneList`, {
    headers: getHeaders(),
    params: {
      ...params
    },
    paramsSerializer: function (params) {
      return qs.stringify(params);
    }
  })
    .then(response => {
      Axios.post(base_url + `deal/${id}/pushZoneList`, {
        headers: getHeaders(),
        params: {
          ...params
        },
        paramsSerializer: function (params) {
          return qs.stringify(params);
        }
      })
        .then(response => console.log(response))
        .catch(error =>
          dispatch({
            type: actionCreators.GET_ZONE_LIST_FAIL,
            payload: error
          })
        );
      dispatch({
        type: actionCreators.GET_ZONE_LIST_SUCCESS,
        payload: response
      });
    })
    .catch(error =>
      dispatch({
        type: actionCreators.GET_ZONE_LIST_FAIL,
        payload: error
      })
    );
};

export const pushZoneList = (id, params = null) => dispatch => {
  dispatch({ type: actionCreators.PUSH_ZONE_LIST });
  Axios.post(base_url + `deal/${id}/pushZoneList`, {
    headers: getHeaders(),
    params: {
      ...params
    },
    paramsSerializer: function (params) {
      return qs.stringify(params);
    }
  })
    .then(response => {
      parseAndShowMessage('success', 'Pushing Zones');
      dispatch({
        type: actionCreators.PUSH_ZONE_LIST_SUCCESS,
        payload: response
      });
    })
    .catch(error =>
      dispatch({
        type: actionCreators.PUSH_ZONE_LIST_FAIL,
        payload: error
      })
    );
};

export const getSelectedItems = id => dispatch => {
  dispatch({ type: actionCreators.GET_DEAL_SELECTED_ITEMS_CHECK });
  Axios.get(base_url + `deal/${id}/selectedItems`, {
    headers: getHeaders()
  })
    .then(response =>
      dispatch({
        type: actionCreators.GET_DEAL_SELECTED_ITEMS_SUCCESS,
        payload: response.data
      })
    )
    .catch(error => {
      dispatch({
        type: actionCreators.GET_DEAL_SELECTED_ITEMS_FAIL,
        payload: error
      });
    });
};

export const activate = data => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_ACTIVATE_CHECK });
  Axios.post(base_url + 'deal/register', data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_ACTIVATE_SUCCESS,
        payload: response
      });
      dispatch(getDealOverviewDetails(data.dealId));
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_ACTIVATE_FAIL,
        payload: error
      })
    );
};

export const alarmSendWelcome = data => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_SEND_WELCOME_CHECK });
  Axios.post(base_url + 'deal/sendEmailWelcomeLetter', data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_SEND_WELCOME_SUCCESS,
        payload: response
      });
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_SEND_WELCOME_FAIL,
        payload: error
      })
    );
};

export const tryToSync = data => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_TRY_TO_SYNC_CHECK });
  Axios.post(base_url + 'deal/tryToSync', data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_TRY_TO_SYNC_SUCCESS,
        payload: response
      });
      dispatch(getDealOverviewDetails(data.dealId));
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_TRY_TO_SYNC_FAIL,
        payload: error
      })
    );
};

export const tryToUnSync = data => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_TRY_TO_UNSYNC_CHECK });
  Axios.post(base_url + 'deal/unSync', data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_TRY_TO_UNSYNC_SUCCESS,
        payload: response
      });
      dispatch(getDealOverviewDetails(data.dealId));
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_TRY_TO_UNSYNC_FAIL,
        payload: error
      })
    );
};

export const terminateAccounts = data => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_TERMINATE_CHECK });
  Axios.post(base_url + 'deal/terminate', data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_TERMINATE_SUCCESS,
        payload: response
      });
      dispatch(getDealOverviewDetails(data.dealId));
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_TERMINATE_FAIL,
        payload: error
      })
    );
};

export const clearData = () => dispatch => {
  localStorage.removeItem('deal_id');
  dispatch({ type: actionCreators.CLEAR, payload: true });
};

export const getLiveDealData = data => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_GET_LIVE_DATA_CHECK });
  Axios.post(base_url + 'deal/getSite', data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_GET_LIVE_DATA_SUCCESS,
        payload: response
      });
      if (data.passcode && data.dealId) {
        dispatch(getDealOverviewDetails(data.dealId));
      }
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_GET_LIVE_DATA_FAIL,
        payload: error
      })
    );
};

export const outOfService = data => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_OUT_OF_SERVICE_CHECK });
  Axios.post(base_url + 'deal/outOfService', data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_OUT_OF_SERVICE_SUCCESS,
        payload: response
      });
      dispatch(getDealOverviewDetails(data.dealId));
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_OUT_OF_SERVICE_FAIL,
        payload: error
      })
    );
};

export const inService = data => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_IN_SERVICE_CHECK });
  Axios.post(base_url + 'deal/inService', data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_IN_SERVICE_SUCCESS,
        payload: response
      });
      dispatch(getDealOverviewDetails(data.dealId));
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_IN_SERVICE_FAIL,
        payload: error
      })
    );
};

export const updateCSInformation = (dealId, data) => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_CS_UPDATE_CHECK });
  Axios.post(base_url + `deal/${dealId}/cs`, data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_CS_UPDATE_SUCCESS,
        payload: response
      });
      dispatch(getDealOverviewDetails(dealId));
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_CS_UPDATE_FAIL,
        payload: error
      })
    );
};

export const verifySerialNumber = (dealId, serialNumber) => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_IMEI_VERIFY_CHECK });
  Axios.post(
    base_url + `deal/${dealId}/imeiVerification`,
    { imei: serialNumber },
    {
      headers: getHeaders()
    }
  )
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_IMEI_VERIFY_SUCCESS,
        payload: response
      });
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_IMEI_VERIFY_FAIL,
        payload: error
      })
    );
};

export const updateAlarmInformation = (dealId, data) => dispatch => {
  dispatch({ type: actionCreators.DEAL_ALARM_ALARM_UPDATE_CHECK });
  Axios.post(base_url + `deal/${dealId}/alarm`, data, {
    headers: getHeaders()
  })
    .then(response => {
      dispatch({
        type: actionCreators.DEAL_ALARM_ALARM_UPDATE_SUCCESS,
        payload: response
      });
      dispatch(getDealOverviewDetails(dealId));
    })
    .catch(error =>
      dispatch({
        type: actionCreators.DEAL_ALARM_ALARM_UPDATE_FAIL,
        payload: error
      })
    );
};
