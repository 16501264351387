import * as actionCreators from '../actionTypes';
import { parseAndShowMessage } from '../../store/common/common';

const initialState = {
  subscriptionList: [],
  currentSubscription: null,
  listloading: false,
  listerror: null,

  deleteMessage: null,
  cancelMessage: null,
  pauseMessage: null,
  isDelete: false,
  isCancel: false,
  isPaused: false,
  isResuming: false,

  submitMessage: null,
  isSubmitted: false,
  subscription: {},
  subscriptionEditData: null,
  subscriptionEditDataLoading: false,
  subscriptionEditDataError: null,
  show: false
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.SET_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload
      };

    case actionCreators.GET_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: state?.subscriptionList?.filter(subscription => {
          let current;
          if (subscription.id === action.payload) {
            current = subscription;
          }
          return current;
        })
      };

    case actionCreators.HANDLE_SUBSCRIPTION_MODAL:
      return {
        ...state,
        show: !state.show
      };

    case actionCreators.GET_SUBSCRIPTION_BY_ID:
      return {
        ...state,
        listloading: true,
        isDelete: false
      };

    case actionCreators.GET_SUBSCRIPTION_BY_ID_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
        listloading: false,
        isDelete: false
      };
    case actionCreators.GET_SUBSCRIPTION_BY_ID_FAIL:
      return {
        ...state,
        listerror: action.payload.request && action.payload.request.statusText,
        listloading: false,
        isDelete: false
      };

    // subscription List
    case actionCreators.GET_SUBSCRIPTIONS:
      return {
        ...state,
        listloading: true,
        isDelete: false
      };

    case actionCreators.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionList: action.payload,
        listloading: false,
        isDelete: false
      };
    case actionCreators.GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        listerror: action.payload.request && action.payload.request.statusText,
        listloading: false,
        isDelete: false
      };

    // subscription Delete
    case actionCreators.SUBSCRIPTION_PAUSE_CHECK:
      parseAndShowMessage('notice', 'Pausing Subscription', null, true); // pNotify = true
      return {
        ...state,
        isPaused: false
      };
    case actionCreators.SUBSCRIPTION_PAUSE_SUCCESS:
      parseAndShowMessage(
        'success',
        'Subscription was successfully paused',
        'Pausing Subscription'
      );
      return {
        ...state,
        isPaused: true,
        pauseMessage: 'Subscription was successfully paused'
      };
    case actionCreators.SUBSCRIPTION_PAUSE_FAIL:
      parseAndShowMessage('error', action.payload, 'Pausing Subscription');
      return {
        ...state,
        isPaused: false
      };

    // subscription Delete
    case actionCreators.SUBSCRIPTION_CANCEL_CHECK:
      parseAndShowMessage('notice', 'Canceling Subscription', null, true); // pNotify = true
      return {
        ...state,
        isCancel: false
      };
    case actionCreators.SUBSCRIPTION_CANCEL_SUCCESS:
      parseAndShowMessage(
        'success',
        'Subscription was successfully canceled',
        'Canceling Subscription'
      );
      return {
        ...state,
        isCancel: true,
        cancelMessage: 'Subscription was successfully canceled'
      };
    case actionCreators.SUBSCRIPTION_CANCEL_FAIL:
      parseAndShowMessage('error', action.payload, 'Canceling Subscription');
      return {
        ...state,
        isCancel: false
      };

    // subscription Delete
    case actionCreators.SUBSCRIPTION_RESUME_CHECK:
      parseAndShowMessage('notice', 'Resuming Subscription', null, true); // pNotify = true
      return {
        ...state,
        isResuming: false
      };
    case actionCreators.SUBSCRIPTION_RESUME_SUCCESS:
      parseAndShowMessage(
        'success',
        'Subscription was successfully resumed',
        'Resuming Subscription'
      );
      return {
        ...state,
        isResuming: true,
        deleteMessage: 'Subscription was successfully resumed'
      };
    case actionCreators.SUBSCRIPTION_RESUME_FAIL:
      parseAndShowMessage('error', action.payload, 'Resuming Subscription');
      return {
        ...state,
        isResuming: false
      };

    // subscription Add
    case actionCreators.SUBSCRIPTION_ADD_CHECK:
      parseAndShowMessage('notice', 'Creating Subscription', null, true); // pNotify = true
      return {
        ...state,
        isSubmitted: false
      };
    case actionCreators.SUBSCRIPTION_ADD_SUCCESS:
      parseAndShowMessage(
        'success',
        'Subscription was successfully created. Refresh your page',
        'Creating Subscription'
      );
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.SUBSCRIPTION_ADD_FAIL:
      parseAndShowMessage(
        'error',
        action.payload,
        'Creating Subscription',
        true
      ); // pNotify = true
      return {
        ...state,
        isSubmitted: false
      };

    // Service UPDATE
    case actionCreators.SUBSCRIPTION_UPDATE_CHECK:
      return {
        ...state,
        isSubmitted: false
      };
    case actionCreators.SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.SUBSCRIPTION_UPDATE_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    default:
      return { ...state };
  }
};
export default subscriptionReducer;
