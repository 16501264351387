import * as actionCreators from '../actionTypes';

const initialState = {
  serviceList: [],
  listloading: false,
  listerror: null,
  deleteMessage: null,
  isDelete: false,

  submitMessage: null,
  isSubmitted: false,

  editService: null,
  editserviceloading: false,
  editserviceerror: null,
  contact: null,
  deal: null,
  schedule: null,
  site: null,
  serviceCall: null,
  technician: null,
  createdBy: null
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.SERVICE_LIST_CHECK:
      return {
        ...state,
        listloading: true,
        isDelete: false
      };

    case actionCreators.SERVICE_LIST_SUCCESS:
      return {
        ...state,
        serviceList: action.payload,
        listloading: false,
        isDelete: false
      };
    case actionCreators.SERVICE_LIST_FAIL:
      return {
        ...state,
        listerror: action.payload.request && action.payload.request.statusText,
        listloading: false,
        isDelete: false
      };

    // edit sevice
    case actionCreators.SERVICE_EDIT_CHECK:
      return {
        ...state,
        editserviceloading: true,
        isDelete: false
      };

    case actionCreators.SERVICE_EDIT_SUCCESS:
      return {
        ...state,
        editService: action.payload,
        editserviceloading: false,
        isDelete: false
      };
    case actionCreators.SERVICE_EDIT_FAIL:
      return {
        ...state,
        editserviceerror: action.payload.request && action.payload.request.statusText,
        editserviceloading: false,
        isDelete: false
      };

    // clear
    case actionCreators.SERVICE_CLEAR_CHECK:
      return {
        ...state,
        editService: null
      };

    // Service UPDATE
    case actionCreators.SERVICE_UPDATE_CHECK:
      return {
        ...state,
        isSubmitted: false
      };
    case actionCreators.SERVICE_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.SERVICE_UPDATE_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    case actionCreators.SERVICE_DELETE_CHECK:
      return {
        ...state,
        isDelete: false
      };
    case actionCreators.SERVICE_DELETE_SUCCESS:
      return {
        ...state,
        isDelete: true,
        deleteMessage: action.payload
      };
    case actionCreators.SERVICE_DELETE_FAIL:
      return {
        ...state,
        isDelete: false
      };

    case actionCreators.SERVICE_ADD_CHECK:
      return {
        ...state,
        isSubmitted: false
      };
    case actionCreators.SERVICE_ADD_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.SERVICE_ADD_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    // Service create
    case actionCreators.SERVICE_CREATE_CHECK:
      return {
        ...state,
        isSubmitted: false
      };
    case actionCreators.SERVICE_CREATE_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.SERVICE_CREATE_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    case actionCreators.GET_SVC_CALL_INFO:
      return {
        ...state
      };
    case actionCreators.GET_SVC_CALL_INFO_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        contact: action.payload && action.payload.contact,
        createdBy: action.payload && action.payload.created_by_user,
        deal: action.payload && action.payload.deal,
        schedule: action.payload && action.payload.schedule,
        site: action.payload && action.payload.site,
        serviceCall: { ...action.payload && action.payload },
        technician: action.payload && action.payload.technician
      };
    case actionCreators.GET_SVC_CALL_INFO_FAIL:
      return {
        ...state
      };
    default:
      return { ...state };
  }
};
export default serviceReducer;
