import taskActions from 'modules/Task/Task.actions';

const initialState = {
  list: [],
  data: {},
  statuses: [],
  creating: false,
  success: false,
  fetching: false,
  deleting: false,
  editing: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case taskActions.FTCHING:
      return {
        ...state,
        fetching: true,
        editing: false,
        deleting: false,
        success: false,
        error: null
      };

    case taskActions.CREATING:
      return {
        ...state,
        fetching: true,
        editing: false,
        creating: true,
        deleting: false,
        error: null,
        success: false
      };

    case taskActions.UPDATING:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: true,
        success: false,
        deleting: false,
        error: null
      };

    case taskActions.DELETING:
      return {
        ...state,
        deleting: true,
        success: false,
        error: null
      };

    case taskActions.FETCH_ONE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    case taskActions.CREATE_SUCCESS:
      return {
        ...state,
        fetching: true,
        data: action.payload,
        creating: false,
        editing: false,
        deleting: false,
        success: true,
        error: null
      };

    case taskActions.FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        success: true,
        error: null
      };

    case taskActions.FETCH_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        success: true,
        error: null
      };

    case taskActions.ERROR:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        success: false,
        error: action.payload
      };

    case taskActions.DELETE_SUCCESS:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        success: true,
        deleting: false,
        error: null
      };

    case taskActions.UPDATING_SUCCESS:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        success: true,
        deleting: false,
        error: null
      };

    default:
      return { ...state };
  }
};
export default reducer;
