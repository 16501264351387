import { combineReducers } from 'redux';
//persist
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//All reducers
import loginReducer from './Login/loginReducer';
import clientReducer from './Client/clientreducer';
import userReducer from './User/userReducer';
import productReducer from './Product/productReducer';
import subscriptionReducer from './Subscriptions/subscriptionReducer';
import siteReducer from 'modules/Site/Site.reducer';
import companyReducer from 'modules/Company/Company.reducer';
import contactReducer from 'modules/Contact/Contact.reducer';
import inventoryReducer from './Inventory/inventoryReducer';
import reducer from './reducer';
import commonReducer from './common/commonreducer';
import dealReducer from './Deal/dealReducer';
import brandReducer from './Brand/brandReducer';
import serviceReducer from './Service/serviceReducer';
import bgReducer from './BackgroundCheck/reducer';
import notesReducer from './Notes/reducer';
import pkgReducer from './Packages/reducer';
import taskReducer from 'modules/Task/Task.reducer';
import newPaymentReducer from './NewPayments/reducer';
import invoiceReducer from 'store/Invoice/invoiceReduce.js';
import scheduler from 'modules/Scheduler/Scheduler.reducer';
import serviceCallReducer from 'modules/ServiceCall/ServiceCall.reducer';
import eventReducer from 'modules/EventLog/Events.reducer';
import statusReducer from 'modules/EventLog/Events.Status.reducer';
import newDealReducer from "./NewDeal/newDealReducer";
import newInvoice from 'modules/NewInvoice/NewIncoiceForm.reducer.js';


const persistConfig = {
  key: 'root',
  storage
};
const rootReducer = combineReducers({
  reducer: reducer,
  common: commonReducer,
  login: persistReducer(persistConfig, loginReducer),
  client: clientReducer,
  product: productReducer,
  site: siteReducer,
  company: companyReducer,
  contact: contactReducer,
  user: userReducer,
  inventory: inventoryReducer,
  deal: dealReducer,
  newDeal: newDealReducer,
  brand: brandReducer,
  bgCheck: bgReducer,
  serviceCall: serviceCallReducer,
  service: serviceReducer,
  subscription: subscriptionReducer,
  notes: notesReducer,
  task: taskReducer,
  packages: pkgReducer,
  payment: newPaymentReducer,
  invoice: invoiceReducer,
  scheduler: scheduler,
  event: eventReducer,
  eventStatuses: statusReducer,
  newInvoice,
});
export default rootReducer;
