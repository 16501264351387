import eventActions from 'modules/EventLog/Events.Status.actions';

const initialState = {
  list: [],
  data: {},
  fetching: false,
  deleting: false,
  editing: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case eventActions.FTCHING:
      return {
        ...state,
        list: [],
        data: null,
        fetching: true,
        editing: false,
        deleting: false,
        error: null
      };

    case eventActions.FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    case eventActions.FETCH_ONE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    case eventActions.ERROR:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        error: action.payload
      };

    case eventActions.DELETING:
      return {
        ...state,
        deleting: true,
        error: null
      };

    case eventActions.DELETE_SUCCESS:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };
    case eventActions.UPDATING:
      return {
        ...state,
        fetching: false,
        editing: true,
        deleting: false,
        error: null
      };
    case eventActions.UPDATING_SUCCESS:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    default:
      return { ...state };
  }
};
export default reducer;
