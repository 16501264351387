import axios from 'axios';
import { base_url } from 'env';
import { store } from 'store/store';
import { getHeaders, parseAndShowMessage } from 'store/common/common';
import Geocode from 'react-geocode';
import qs from 'qs';
import { calcAvailCredit } from 'utils/quickFunc';
axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'CONTACT';

const contactActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  FETCHING_PAYMENT_METHODS: `${prefix}_FETCHING_PAYMENT_METHODS`,
  FETCH_ALL_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  GET_PAYMENT_METHODS_SUCCESS: `${prefix}_GET_PAYMENT_METHODS_SUCCESS`,
  ADD_PAYMENT_METHOD_SUCCESS: `${prefix}ADD_PAYMENT_METHOD_SUCCESS`,
  ADDING: `${prefix}_ADDING`,
  DELETING: `${prefix}_DELETING`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  UPDATING: `${prefix}_UPDATING`,
  UPDATING_SUCCESS: `${prefix}_UPDATING_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  getAll: (params = null) => dispatch => {
    dispatch({ type: contactActions.FETCHING });

    axios
      .get(`${base_url}resources/contact/`, {
        params: params
      })
      .then(response =>
        dispatch({
          type: contactActions.FETCH_ALL_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: contactActions.ERROR, payload: error }));
  },
  getById: id => dispatch => {
    dispatch({ type: contactActions.FETCHING });
    let lat, lng;

    axios
      .get(base_url + `resources/Contact/${id}`)
      .then(response => {
        Geocode.fromAddress(
          `${response.data.address}, ${response.data.city}, ${response.data.state} ${response.data.zip}, ${response.data.country}`
        )
          .then(res => {
            lat = res.results[0]?.geometry?.location?.lat ?? '';
            lng = res.results[0]?.geometry?.location?.lng ?? '';
          })
          .then(() =>
            dispatch({
              type: contactActions.FETCH_ONE_SUCCESS,
              payload: { ...response.data, lat, lng }
            })
          );
      })
      .catch(error => dispatch({ type: contactActions.ERROR, payload: error }));
  },
  getByModel: (model, modelId) => dispatch => {
    dispatch({ type: contactActions.FETCHING });

    axios
      .get(base_url + `contact/${model}/${modelId}`)
      .then(response =>
        dispatch({
          type: contactActions.SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: contactActions.ERROR, payload: error }));
  },
  delete: id => dispatch => {
    dispatch({ type: contactActions.DELETING });

    axios
      .delete(base_url + `contact/${id}`)
      .then(response =>
        dispatch({
          type: contactActions.DELETE_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: contactActions.ERROR, payload: error }));
  },
  update: (id, data) => dispatch => {
    dispatch({ type: contactActions.UPDATING });
    axios
      .put(`${base_url}contact/${id}`, data)
      .then(response => {
        parseAndShowMessage('success', response, 'Contact Update');
        dispatch({
          type: contactActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Contact Update');
        dispatch({ type: contactActions.ERROR, payload: error });
      });
  },
  getPaymentMethods: (modelId, model) => dispatch => {
    dispatch({ type: contactActions.FETCHING_PAYMENT_METHODS });
    axios
      .get(`${base_url}resources/${model}/${modelId}/paymentMethods`, {
        headers: getHeaders()
      })
      .then(response => {
        return dispatch({
          type: contactActions.GET_PAYMENT_METHODS_SUCCESS,
          payload: response.data.map(item =>
            item.type == 'credit_memo'
              ? {
                  ...item,
                  value: item.id,
                  label: `${item.alias ?? item.friendly_type} - ${
                    item.last_four
                  } Available: $${calcAvailCredit(
                    item.amount_limit,
                    item.total_charges_in_cents
                  ).toFixed(2)}`
                }
              : {
                  ...item,
                  value: item.id,
                  label: `${item.name_on_file}: ${item.alias ?? item.friendly_type} - ${item.last_four}`
                }
          )
        });
      })
      .catch(error =>
        dispatch({
          type: contactActions.ERROR,
          payload: error
        })
      );
  },
  addPaymentMethod: data => {
    return async dispatch => {
      dispatch({ type: contactActions.ADDING });

      await axios
        .post(base_url + 'payment-method/create', data, {
          headers: getHeaders()
        })
        .then(response => {
          parseAndShowMessage('success', response, 'Payment Method Create');
          return dispatch({
            type: contactActions.ADD_PAYMENT_METHOD_SUCCESS,
            payload: response.data
          });
        })
        .catch(error => {
          parseAndShowMessage('error', error, 'Payment Method Create');
          dispatch({
            type: contactActions.ERROR,
            payload: error
          });
        });
    };
  }
};

export default contactActions;
