/* eslint-disable react/no-multi-comp */
import React, {Suspense, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ErrorBoundry from '../Containers/Other/ErrorClass';
  import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Page from 'components/Page';
import {pageHandler} from 'utils/quickFunc';
import {colors, createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core';

import '../../node_modules/font-awesome/scss/font-awesome.scss';
import 'assets/scss/style.scss';
import moment from 'moment-timezone';
import ScrollToTop from './components/ScrollToTop';
import routes from '../Routes/route';
import {Spinner} from 'react-bootstrap';
import Fade from '@material-ui/core/Fade';

import axios from 'axios';

import {base_url} from 'env';

import {geolocated} from 'react-geolocated';

import {getHeaders} from '../store/common/common';

import history from '../store/history';
import {lazyWithRetry} from "../Routes/lazyWithRetry";


const AdminLayout = React.lazy(() => import("./layout/AdminLayout"));
const Error404 = React.lazy(() => import("../Containers/Other/401"));

// let theme = createMuiTheme({
//   '@global': {
//     '.MuiListItem-root.Mui-selected': {
//       background: '#34A9F5'
//     },
//     '.MuiListItem-root.Mui-selected:hover': {
//       background: '#34A9F5'
//     },
//     '.MuiButton-containedSecondary': {
//       background: 'red'
//     },
//     '.MuiButton-containedSecondary:hover': {
//       background: 'red'
//     },
//     '.MuiButton-colorInherit': {
//       background: '#34A9F5'
//     },
//     '.MuiButton-colorInherit:hover': {
//       background: '#34A9F5'
//     },
//     '.MuiButton-containedPrimary': {
//       background: '#34A9F5'
//     },
//     '.MuiButton-containedPrimary:hover': {
//       background: '#34A9F5'
//     }
//   }
// });
// theme = responsiveFontSizes(theme);

const menu = routes.map((route, index) => {
  return route.component ? (
    <Route
      exact={route.exact}
      key={index}
      name={route.name}
      path={route.path}
      render={props => (
        <ErrorBoundry {...props}>
          <route.component {...props} />
        </ErrorBoundry>
      )}
    />
  ) : (
    <Error404 />
  );
});

const checkAuth = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return false;
  }

  return true;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const submitLocation = myLocation => {
    axios
      .post(
        base_url + 'submit-location',
        {
          latitude: myLocation.latitude,
          longitude: myLocation.longitude
        },
        {
          headers: getHeaders()
        }
      )
      .then(res => {})
      .catch(err => console.log(err));
  };

  const success = async pos => {
    const crd = await pos.coords;
    // submitLocation(crd);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: 100
    }
  }));
  const classes = useStyles();

  // window.onhashchange = function() {
  //   window.location.reload();
  // };

  React.useEffect(() => {
    // window.onhashchange()
    // setPrevPath(history.location);
    moment.tz.setDefault(
      localStorage.getItem('time_zone') || 'America/NewYork'
    );
    history.location.pathname !== '/login' &&
      navigator.geolocation.getCurrentPosition(success, () => '', {
        enableHighAccuracy: true,
        maximumAge: 0
      });

    history.listen((location, action) => {
      // window.location.reload();
      const options = {
        enableHighAccuracy: true,
        maximumAge: 0
      };
      if (location.pathname != '/login') {
        navigator.geolocation.getCurrentPosition(success, () => '', options);
      }
    });

    localStorage.setItem('prevPath', history.location.pathname);
  }, [history.location.pathname]);


  return (
    <Fade>
      <Route
        {...rest}
        render={props =>
          checkAuth() ? (
            <Page className={classes.root} title={pageHandler()}>
              <Component {...props} {...rest} />{' '}
            </Page>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    </Fade>
  );
};

const lightTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600]
        }
      }
    }
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600]
    },
    background: {
      default: colors.common.white,
      // dark: '#f4f6f8', turn this back on later
      paper: colors.common.white
    },
    primary: {
      main: '#5A99D7' //colors.indigo[600]
    },
    secondary: {
      main: '#5A99D7' // '#5850EC'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px'
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px'
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px'
    },
    p: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5em'
    },
    overline: {
      fontWeight: 500
    }
  }
  // shadows: softShadows
});

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)'
    },
    background: {
      default: '#282C34',
      dark: '#1c2025',
      paper: '#282C34'
    },
    primary: {
      main: '#5A99D7'
    },
    secondary: {
      main: '#5A99D7'
    },
    text: {
      primary: '#e6e5e8',
      secondary: '#adb0bb'
    }
  }
  // shadows: strongShadows
});

const App = () => {
  const [theme, setTheme] = useState('light');

  const toggleDarkTheme = () => {
    let newPaletteType = theme == 'light' ? 'light' : 'dark';
    setTheme(newPaletteType);
    sessionStorage.setItem('theme2', JSON.stringify(newPaletteType));
  };

  React.useEffect(() => {
    localStorage.removeItem('theme');
  }, []);

  React.useEffect(() => {
    const savedTheme = sessionStorage.getItem('theme2');
    if (savedTheme) {
      setTheme({
        palette: {
          type: JSON.parse(savedTheme)
        }
      });
    }
  }, []);

  // const muiTheme = createMuiTheme(theme);

  return (
    <ScrollToTop>
      <MuiThemeProvider theme={lightTheme}>
        <Suspense fallback={<Spinner />}>
          <Switch>
            {menu}
            <AuthRoute
              component={AdminLayout}
              path="/"
              setTheme={toggleDarkTheme}
              theme={theme}
            />
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </ScrollToTop>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true
  },
  userDecisionTimeout: 5000
})(App);
