import React from 'react';
import {lazyWithRetry} from "./lazyWithRetry";

// const SignIn1 = lazyWithRetry(() => import('../Containers/Authentication/SignIn'));
// const ResetPassword = lazyWithRetry(() =>
//   import('../Containers/Authentication/ResetPassword')
// );
// const ClientSetup = lazyWithRetry(() => import('modules/OnBoarding'));

const SignIn1 = React.lazy(() => lazyWithRetry(() => import("../Containers/Authentication/SignIn"), "SignIn1"));
const ResetPassword = React.lazy(() => lazyWithRetry(() => import("../Containers/Authentication/ResetPassword"), "ResetPassword"));
const ClientSetup = React.lazy(() => lazyWithRetry(() => import("modules/OnBoarding"), "ClientSetup"));


const ChangePassword = React.lazy(() => lazyWithRetry(() => import("Containers/Authentication/ChangePassword"), "ChangePassword"));
const customerPayment = React.lazy(() => lazyWithRetry(() => import("modules/CustomerPayment"), "customerPayment"));

const ConsolidatePayment = React.lazy(() => lazyWithRetry(() => import("modules/ConsolidatedInvoice/ConsolidatePayment"), "modules/ConsolidatedPayment"));

const customerPortalInvoice = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/ViewInvoice"), "customerPortalInvoice"));
const customerPortalEstimatesOld = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/Estimates/EstimatesView"), "customerPortalEstimates"));
const customerPortalEstimates = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/NewEstimates"), "customerPortalEstimates"));
const alarmSystemInfoView = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/public/System/views/AlarmSystemInformationView"), "alarmSystemInfoView"));


const customerPortal = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/Dashboard.js"), "customerPortal"));
const customerSignIn = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/SignIn.js"), "customerSignIn"));
const customerSignUp = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/SignUp.js"), "customerSignUp"));
const customerSignUpUser = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/SignUpUser.jsx"), "customerSignUpUser"));


const customerInvoices = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/Invoices.js"), "customerInvoices"));
const customerPayInvoice = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/PayInvoice.jsx"), "customerPayInvoice"));
const customerAccountVerification = React.lazy(() => lazyWithRetry(() => import("views/customerPortal/AccountVerification.jsx"), "customerAccountVerification"));
const privacyPolicy = React.lazy(() => lazyWithRetry(() => import("Containers/Authentication/PrivacyPolicy"), "privacyPolicy"));
const termsOfService = React.lazy(() => lazyWithRetry(() => import("Containers/Authentication/TermsOfService"), "termsOfService"));


const GoalsOpenLinkDashboard = React.lazy(() => lazyWithRetry(() => import("modules/Settings/Goals/GoalsDashboard"), "GoalsDashboard"));



const route = [
  { path: '/', exact: true, name: 'Signin', component: SignIn1 },
  {
    path: '/reset',
    exact: true,
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/clientsetup',
    exact: true,
    name: 'Client Setup',
    component: ClientSetup
  },
  {
    path: '/Goals-open-link/:hash',
    exact: true,
    name: 'GoalsOpenLinkDashboard',
    component: GoalsOpenLinkDashboard
  },
  {
    path: '/change-password/:token/:email',
    exact: true,
    name: 'Change Password',
    component: ChangePassword
  },
  {
    path: '/privacyPolicy',
    exact: true,
    name: 'Privacy Ploicy',
    component: privacyPolicy
  },
  {
    path: '/customerPayment/:reference',
    exact: true,
    name: 'Customer',
    component: customerPayment
  },
  {
    path: '/consolidated-invoice/pay/:reference',
    exact: true,
    name: 'ConsolidatePayment',
    component: ConsolidatePayment
  },
  {
    path: '/customer-portal-invoice/:reference',
    exact: true,
    name: 'Customer',
    component: customerPortalInvoice
  },
  {
    path: '/customer-portal-estimates/:reference/:tab',
    exact: true,
    name: 'Customer',
    component: customerPortalEstimates
  },
  {
    path: '/customer-portal-estimates-old/:reference',
    exact: true,
    name: 'Customer old estimate',
    component: customerPortalEstimatesOld
  },
  {
    path: '/customer-portal-systems/alarm-system-info',
    exact: true,
    name: 'Alarm System Info',
    component: alarmSystemInfoView
  },
  {
    path: '/customerPortal',
    exact: true,
    name: 'Portal',
    component: customerPortal
  },
  {
    path: '/customer/signin',
    exact: true,
    name: 'Customer SignIn',
    component: customerSignIn
  },
  {
    // http://localhost:3000/customer/accept-invitation/44906
    path: '/customer/accept-invitation/:invite_code',
    exact: true,
    name: 'Customer SignUp',
    component: customerSignUp
  },
  {
    path: '/customer/signup/:invite_code',
    exact: true,
    name: 'Customer SignUp User',
    component: customerSignUpUser
  },
  {
    path: '/customer/invoices',
    exact: true,
    name: 'Customer Invoices',
    component: customerInvoices
  },
  {
    path: '/customer/payinvoice',
    exact: true,
    name: 'Pay Invoices',
    component: customerPayInvoice
  },
  {
    path: '/customer/account-verification',
    exact: true,
    name: 'Customer Account Verification',
    component: customerAccountVerification
  },
  {
    path: '/termsOfService',
    exact: true,
    name: 'Terms Of Service',
    component: termsOfService
  },
];

export default route;
