import axios from 'axios';
import { base_url } from 'env';
import { getHeaders, parseAndShowMessage } from 'store/common/common';

axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'SCHEDULER';

const schedulerActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  CREATING: `${prefix}_CREATING`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  DELETING: `${prefix}_DELETING`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  UPDATING: `${prefix}_UPDATING`,
  FETCH_TECHNICIANS: `${prefix}_FETCH_TECHNICIANS`,
  FETCH_TECHNICIANS_SUCCESS: `${prefix}_FETCH_TECHNICIANS_SUCCESS`,
  UPDATING_SUCCESS: `${prefix}_UPDATING_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  create: (data, model, modelId) => dispatch => {
    dispatch({ type: schedulerActions.CREATING });

    let url = model.replace(/ /g, '');
    if (url !== 'ServiceCall') {
      url += 's';
    }

    axios
      .post(`${base_url}event/${model}/${modelId}`, {
        ...data,
        googleData: {
          description: `${data.notes} \n<a href=${window.location.href}>View ${model}</a>`
        }
      })
      .then(response => {
        parseAndShowMessage('success', response, 'Schedule Event');
        dispatch({
          type: schedulerActions.CREATE_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Schedule Event');
        dispatch({ type: schedulerActions.ERROR, payload: error });
      });
  },
  getAll: () => dispatch => {
    dispatch({ type: schedulerActions.FETCHING });

    axios
      .get(base_url + 'event/getAll')
      .then(response =>
        dispatch({
          type: schedulerActions.FETCH_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: schedulerActions.ERROR, payload: error })
      );
  },
  getTechnicians: () => dispatch => {
    dispatch({ type: schedulerActions.FETCH_TECHNICIANS });

    axios
      .get(base_url + 'user/technicians')
      .then(response =>
        dispatch({
          type: schedulerActions.FETCH_TECHNICIANS_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: schedulerActions.ERROR, payload: error })
      );
  },
  getById: id => dispatch => {
    dispatch({ type: schedulerActions.FETCHING });

    axios
      .get(base_url + `event/${id}`)
      .then(response =>
        dispatch({
          type: schedulerActions.SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: schedulerActions.ERROR, payload: error })
      );
  },
  getByModel: (model, modelId) => dispatch => {
    dispatch({ type: schedulerActions.FETCHING });

    axios
      .get(base_url + `event/${model}/${modelId}`)
      .then(response =>
        dispatch({
          type: schedulerActions.SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: schedulerActions.ERROR, payload: error })
      );
  },
  delete: id => dispatch => {
    dispatch({ type: schedulerActions.DELETING });

    axios
      .delete(base_url + `event/${id}`)
      .then(response =>
        dispatch({
          type: schedulerActions.DELETE_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: schedulerActions.ERROR, payload: error })
      );
  },
  update: id => dispatch => {
    dispatch({ type: schedulerActions.UPDATING });

    axios
      .put(base_url + `event/${id}`)
      .then(response =>
        dispatch({
          type: schedulerActions.UPDATING_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: schedulerActions.ERROR, payload: error })
      );
  }
};

export default schedulerActions;
