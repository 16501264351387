import * as actionCreators from '../actionTypes';

const initialState = {
  noteList: [],
  newNote: '',
  fetching: false,
  error: null
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.GET_NOTES:
      return {
        ...state,
        noteList: [],
        newNote: '',
        fetching: true
      };
    case actionCreators.GET_NOTES_SUCCESS:
      return {
        ...state,
        fetching: false,
        noteList: action.payload
      };
    case actionCreators.GET_NOTES_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload.request && action.payload.request.statusText
      };

    case actionCreators.ADD_NOTE:
      return {
        ...state,
        noteList: [],
        newNote: '',
        fetching: true
      };
    case actionCreators.ADD_NOTE_SUCCESS:
      return {
        ...state,
        fetching: false,
        newNote: action.payload
      };
    case actionCreators.ADD_NOTE_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload.request && action.payload.request.statusText
      };

    default:
      return { ...state };
  }
};
export default notesReducer;
