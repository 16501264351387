import axios from 'axios';
import { base_url } from 'env';
import { getHeaders, parseAndShowMessage } from 'store/common/common';
import history from 'store/history';

axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'SERVICE_CALL';

const serviceCallActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  UPDATING: `${prefix}_UPDATING`,
  CANCEL: `${prefix}_CANCEL`,
  CREATING: `${prefix}_CREATING`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  DELETING: `${prefix}_DELETING`,
  SUBMIT_SIGNATURE: `${prefix}_SUBMIT_PAYMENT`,
  SUBMIT_SIGNATURE_SUCCESS: `${prefix}_SUBMIT_SIGNATURE_SUCCESS`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  CANCEL_SUCCESS: `${prefix}_CANCEL_SUCCESS`,
  UPDATING_SUCCESS: `${prefix}_UPDATING_SUCCESS`,
  UPDATING_SUMMARY_SUCCESS: `${prefix}_UPDATING_SUMMARY_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  create: data => dispatch => {
    dispatch({ type: serviceCallActions.CREATING });
     // delete data.notify_customer;
     // delete data.notify_tech;
    axios
      .post(base_url + 'serviceCall/create', data)
      .then(response => {
        dispatch({
          type: serviceCallActions.CREATE_SUCCESS,
          payload: response.data
        });
        parseAndShowMessage(
          'success',
          'Service Call Created Successfully',
          'Create Service Call'
        );
        setTimeout(() => {
          history.push(`/service`);
        }, 2000);
      })
      .catch(error => {
        dispatch({ type: serviceCallActions.ERROR, payload: error });
      });
  },
  getAll: () => dispatch => {
    dispatch({ type: serviceCallActions.FETCHING });

    axios
      .get(base_url + 'event/getAll')
      .then(response =>
        dispatch({
          type: serviceCallActions.FETCH_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: serviceCallActions.ERROR, payload: error })
      );
  },
  getById: id => dispatch => {
    dispatch({ type: serviceCallActions.FETCHING });

    axios
      .get(base_url + `resources/serviceCall/${id}`)
      .then(response =>
        dispatch({
          type: serviceCallActions.FETCH_ONE_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: serviceCallActions.ERROR, payload: error })
      );
  },
  getByModel: (model, modelId) => dispatch => {
    dispatch({ type: serviceCallActions.FETCHING });

    axios
      .get(base_url + `event/${model}/${modelId}`)
      .then(response =>
        dispatch({
          type: serviceCallActions.SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: serviceCallActions.ERROR, payload: error })
      );
  },
  delete: id => dispatch => {
    dispatch({ type: serviceCallActions.DELETING });

    axios
      .delete(base_url + `event/${id}`)
      .then(response =>
        dispatch({
          type: serviceCallActions.DELETE_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({ type: serviceCallActions.ERROR, payload: error })
      );
  },
  updateStatus: id => dispatch => {
    dispatch({ type: serviceCallActions.UPDATING });

    axios
      .put(base_url + `event/${id}`)
      .then(response => {
        parseAndShowMessage('success', response, 'Status Update');
        dispatch({
          type: serviceCallActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Status Update');
        dispatch({ type: serviceCallActions.ERROR, payload: error });
      });
  },
  // updateSummary: id => dispatch => {
  //   dispatch({ type: serviceCallActions.UPDATING });

  //   axios
  //     .put(`serviceCall/${id}/submit`)
  //     .then(response => {
  //       parseAndShowMessage('success', response, 'Summary Update');
  //       dispatch({
  //         type: serviceCallActions.UPDATING_SUMMARY_SUCCESS,
  //         payload: response.data
  //       });
  //     })
  //     .catch(error => {
  //       parseAndShowMessage('error', error, 'Summary Update');
  //       dispatch({ type: serviceCallActions.ERROR, payload: error });
  //     });
  // },
  update: (id, data) => async dispatch => {
    dispatch({ type: serviceCallActions.UPDATING });

    await axios
      .put(base_url + `serviceCall/${id}`, data)
      .then(response => {
        parseAndShowMessage('success', response, 'Service Call Update');
        dispatch({
          type: serviceCallActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Service Call Update');
        dispatch({ type: serviceCallActions.ERROR, payload: error });
      });
  },
  cancel: (id, data) => async dispatch => {
    dispatch({ type: serviceCallActions.CANCEL });

    await axios
      .put(base_url + `serviceCall/${id}/cancel`, data)
      .then(response => {
        parseAndShowMessage('success', response, 'Service Call Cancel');
        dispatch({
          type: serviceCallActions.CANCEL_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Service Call Cancel');
        dispatch({ type: serviceCallActions.ERROR, payload: error });
      });
  },
  submitSign: (modelId, data) => dispatch => {
    dispatch({ type: serviceCallActions.SUBMIT_SIGNATURE });

    axios
      .put(base_url + `serviceCall/${modelId}/submit`, data)
      .then(response => {
        parseAndShowMessage('success', response, 'Service Call Review');
        dispatch({
          type: serviceCallActions.SUBMIT_SIGNATURE_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Service Call Review');
        dispatch({ type: serviceCallActions.ERROR, payload: error });
      });
  }
};

export default serviceCallActions;
