import * as actionCreators from '../actionTypes';
import moment from 'moment';
import {
  CHAT_NOTIFICATION_CHECK,
  LOGIN_BY_FORCE_PASSWORD_CHANGE,
  LOGIN_BY_FORCE_PASSWORD_DELETE,
  LOGIN_TWOAUTH
} from "../actionTypes";

const initialState = {
  data: [],
  user: {},
  adminData: [],
  token: null,
  errorMail: null,
  errroMailStatus: null,
  registrationSuccess: false,
  loggedIn: false,

  loginError: null,
  forgot_password_msg: null,
  adminFlag: false,

  role: null,
  logo: null,
  client_id: null,
  client: null,

  chatNotification: 0,

  password_change_code: '',

  twoAuth: {user_id: null,timeout_code: '',verification_type: ''},

  mtu: {
    logging_in_id: null,
    is_logging_in: false,
    login_error: undefined
  }
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return {
        client: state.client,
        token: null,
        user: state.user,
        client_id: state.user.client_id,
        adminFlag: false,
        adminData: state.adminData
      };

    case actionCreators.LOGIN_CHECK:
      return {
        ...state,
        loggedIn: false
        // token:action.payload.token
      };

    case actionCreators.LOGIN_SUCCESS:
      if (action.payload == 'adminlogin') {
        localStorage.setItem(
          'time_zone',
          state.adminData?.assigned_time_zone?.time_zone?.name
        );
        moment.tz.setDefault(state.adminData?.assigned_time_zone.time_zone?.name);
        return {
          ...state,
          loggedIn: true,
          user: state.adminData,
          token: state.adminData.token,
          adminData: [],
          adminFlag: false,
          twoAuth: {},
        };
      } else {

        return {
          ...state,
          loggedIn: true,
          user: action.payload.user,
          client_id: action.payload.user.client_id,
          role: action.payload.user.roles[0],
          token: action.payload.token,
          client: action.payload.user?.client,
          twoAuth: {},
        };
      }
    case actionCreators.LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        loginError: action.payload.request && action.payload.request.statusText,
        data: action.payload
      };
    case actionCreators.LOGIN_TWOAUTH:
      return {
        ...state,
        // loggedIn: false,
        // loginError: action.payload.request && action.payload.request.statusText,
        twoAuth: action.payload
      };

      case actionCreators.LOGIN_BY_FORCE_PASSWORD_CHANGE:
      return {
        ...state,
        password_change_code: action.payload
      };
      case actionCreators.LOGIN_BY_FORCE_PASSWORD_DELETE:
      return {
        ...state,
        password_change_code: ''
      };


    case actionCreators.LOGOUT_CHECK:
      return {
        ...state,
        loggedIn: true
      };

    case actionCreators.LOGOUT_SUCCESS:
      Object.keys(state).forEach(key => {
        localStorage.removeItem(key);
      });

      return {
        data: [],
        token: null,
        errorMail: null,
        errroMailStatus: null,
        registrationSuccess: false,
        loggedIn: false,
        user: {},
        forgot_password_msg: null,
        client: null
      };

    case actionCreators.LOGOUT_ERROR:
      return {
        ...state,
        loggedIn: true,
        data: action.payload.request && action.payload.request.statusText
      };

    case actionCreators.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgot_password_msg: action.payload.data
      };
    }
    case actionCreators.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        forgot_password_msg: action.payload.data
      };
    }

    case actionCreators.CHECK_EMAIL:
      return {
        ...state,
        data: [],
        user: {},
        adminData: [],
        errroMailStatus: action.payload.status,
        errorMail: action.payload.data
      };

    case actionCreators.MAIL_ERROR: {
      return {
        ...state,
        errroMailStatus: false,
        errorMail: action.payload.request && action.payload.request.statusText
      };
    }
    case actionCreators.USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        registrationSuccess: true
      };
    }
    case actionCreators.USER_REGISTRATION_ERROR: {
      return {
        ...state,
        registrationSuccess: false
      };
    }
    case actionCreators.ADD_ADMIN_USER: {
      const data = {
        ...state.user,
        data: [],
        user: {},
        adminData: [],
        token: state.token
      };
      return {
        ...state,
        adminFlag: true,
        adminData: data
      };
    }
    case actionCreators.LOGO_SUCCESS: {
      return {
        ...state,
        user: { ...state.user, logo: action.payload }
      };
    }

    case actionCreators.UPDATE_CLIENT_INFO_SUCCESS: {
      return {
        ...state,
        client: action.payload
      };
    }

    case actionCreators.CHAT_NOTIFICATION_SUCCESS : {
      return {
        ...state,
        chatNotification: action.payload
      };
    }
    case actionCreators.CHAT_NOTIFICATION_CHECK : {
      return {
        ...state,
        chatNotification: 0
      };
    }
    case actionCreators.CHAT_NOTIFICATION_FAIL : {
      return {
        ...state,
        chatNotification: 0
      };
    }

    case actionCreators.MTU_LOGIN_PROGRESS : {
      return {
        ...state,
        mtu: action.payload
      };
    }

    case actionCreators.MTU_LOGIN_FINISH : {
      return {
        ...state,
        mtu: action.payload
      };
    }

    case actionCreators.MTU_LOGIN_ERROR : {

      return {
        ...state,
        mtu: action.payload
      };
    }

    case actionCreators.MTU_LOGIN_SUCCESS : {

      return {
        ...state,
        mtu: action.payload
      };
    }

    default:
      return { ...state };
  }
};
export default loginReducer;
