import companyActions from 'modules/Company/Company.actions';
import contactActions from 'modules/Contact/Contact.actions';
import siteActions from 'modules/Site/Site.actions';
import schedulerActions from 'modules/Scheduler/Scheduler.actions';
import serviceCallActions from 'modules/ServiceCall/ServiceCall.actions';
import eventActions from 'modules/EventLog/Events.actions';
import taskActions from 'modules/Task/Task.actions';
import eventStatusActions from 'modules/EventLog/Events.Status.actions';
import newInvoiceCallActions from 'modules/NewInvoice/NewInvoiceForm.actions';

export const actions = {
  company: companyActions,
  serviceCall: serviceCallActions,
  site: siteActions,
  contact: contactActions,
  scheduler: schedulerActions,
  event: eventActions,
  task: taskActions,
  eventStatus: eventStatusActions,
  newInvoice: newInvoiceCallActions,
};

export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const CHANGE_PRE_LAYOUT = 'CHANGE_PRE_LAYOUT';
export const CHECK_PASSCODE = 'CHECK_PASSCODE';
export const CHECK_PASSCODE_SUCCESS = 'CHECK_PASSCODE_SUCCESS';
export const CHECK_PASSCODE_FAIL = 'CHECK_PASSCODE_FAIL';
export const GET_TEST_CATEGORIES = 'GET_TEST_CATEGORIES';
export const GET_TEST_CATEGORIES_SUCCESS = 'GET_TEST_CATEGORIES_SUCCESS';
export const GET_TEST_CATEGORIES_FAIL = 'GET_TEST_CATEGORIES_FAIL';

export const SUBMIT_TEST_DETAILS = 'SUBMIT_TEST_DETAILS';
export const SUBMIT_TEST_DETAILS_SUCCESS = 'SUBMIT_TEST_DETAILS_SUCCESS';
export const SUBMIT_TEST_DETAILS_FAIL = 'SUBMIT_TEST_DETAILS_FAIL';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAIL = 'GET_SUBSCRIPTIONS_FAIL';

export const SUBSCRIPTION_CANCEL_CHECK = 'SUBSCRIPTION_CANCEL_CHECK';
export const SUBSCRIPTION_CANCEL_SUCCESS = 'SUBSCRIPTION_CANCEL_SUCCESS';
export const SUBSCRIPTION_CANCEL_FAIL = 'SUBSCRIPTION_CANCEL_FAIL';

export const SUBSCRIPTION_PAUSE_CHECK = 'SUBSCRIPTION_PAUSE_CHECK';
export const SUBSCRIPTION_PAUSE_SUCCESS = 'SUBSCRIPTION_PAUSE_SUCCESS';
export const SUBSCRIPTION_PAUSE_FAIL = 'SUBSCRIPTION_PAUSE_FAIL';

export const SUBSCRIPTION_ADD_CHECK = 'SUBSCRIPTION_ADD_CHECK';
export const SUBSCRIPTION_ADD_SUCCESS = 'SUBSCRIPTION_ADD_SUCCESS';
export const SUBSCRIPTION_ADD_FAIL = 'SUBSCRIPTION_ADD_FAIL';

export const SUBSCRIPTION_UPDATE_CHECK = 'SUBSCRIPTION_UPDATE_CHECK';
export const SUBSCRIPTION_UPDATE_SUCCESS = 'SUBSCRIPTION_UPDATE_SUCCESS';
export const SUBSCRIPTION_UPDATE_FAIL = 'SUBSCRIPTION_UPDATE_FAIL';
