export default {
  defaultPath: '/dashboard',
  basename: '/', // only at build time to set, like /datta-able
  layout: 'vertical', // vertical, horizontal
  preLayout: '', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: false, // mini-menu
  layoutType: 'menu-dark', // menu-dark, menu-light, dark
  navIconColor: false,
  headerBackColor: 'header-default', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: false,
  boxLayout: false,
  navDropdownIcon: 'style1', // style1, style2, style3
  navListIcon: null, // style1, style2, style3, style4, style5, style6
  navActiveListColor: 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: false,
  layout6Background:
    'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
  layout6BackSize: '' // used only for pre-layout = layout-6
};

export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};


// inside package json"homepage": "http://html.codedthemes.com/datta-able/react/default",

//  import { connect } from 'react-redux';

// const themeSettings = props => ({
//   defaultPath: '/dashboard',
//   basename: '/', // only at build time to set, like /datta-able
//   // layout: 'vertical', // vertical, horizontal
//   // preLayout: '', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
//   // collapseMenu: false, // mini-menu
//   // layoutType: 'menu-dark', // menu-dark, menu-light, dark
//   // navIconColor: false,
//   // headerBackColor: 'header-default', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
//   // navBackColor: 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
//   // navBrandColor: 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
//   // navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
//   // rtlLayout: false,
//   // navFixedLayout: true,
//   // headerFixedLayout: false,
//   // boxLayout: false,
//   // navDropdownIcon: 'style1', // style1, style2, style3
//   // navListIcon: 'style1', // style1, style2, style3, style4, style5, style6
//   // navActiveListColor: 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
//   // navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
//   // navListTitleHide: false,
//   // configBlock: true,
//   // layout6Background:
//   //   'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
//   // layout6BackSize: '' // used only for pre-layout = layout-6
//   layout: props.layout,
//   preLayout: props.preLayout,
//   collapseMenu: props.collapseMenu,
//   layoutType: props.layoutType,
//   navIconColor: props.navIconColor,
//   headerBackColor: props.headerBackColor,
//   navBackColor: props.navBackColor,
//   navBrandColor: props.navBrandColor,
//   navBackImage: props.navBackImage,
//   rtlLayout: props.rtlLayout,
//   navFixedLayout: props.navFixedLayout,
//   headerFixedLayout: props.headerFixedLayout,
//   boxLayout: props.boxLayout,
//   navDropdownIcon: props.navDropdownIcon,
//   navListIcon: props.navListIcon,
//   navActiveListColor: props.navActiveListColor,
//   navListTitleColor: props.navListTitleColor,
//   navListTitleHide: props.navListTitleHide,
//   layout6Background: props.layout6Background
// });

// const mapStateToProps = state => {
//   return {
//     defaultPath: state.reducer.defaultPath,
//     isFullScreen: state.reducer.isFullScreen,
//     collapseMenu: state.reducer.collapseMenu,
//     configBlock: state.reducer.configBlock,
//     layout: state.reducer.layout
//   };
// };

// export default connect(
//   mapStateToProps,
//   null
// )(themeSettings);

// // inside package json"homepage": "http://html.codedthemes.com/datta-able/react/default",
