import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import rootReducer from './combineReducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const customMidleware = store => next => action => {
  const res = next(action);
  // console.table(res);
  return res;
};

// const loggerCheck = process.env.NODE_ENV === 'development' ? logger : '';

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, 
    // logger, 
    customMidleware))
);
const testStore = (preloadedState) => {

  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk, customMidleware))
  );
};

let persistor = persistStore(store);

export { store, persistor, testStore };
