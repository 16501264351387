import siteActions from 'modules/Site/Site.actions';

const initialState = {
  list: [],
  data: {},
  fetching: false,
  deleting: false,
  editing: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case siteActions.FETCHING:
      return {
        ...state,
        fetching: true,
        editing: false,
        deleting: false,
        error: null
      };

    case siteActions.FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    case siteActions.FETCH_ONE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    case siteActions.ERROR:
      return {
        ...state,
        fetching: false,
        editing: false,
        deleting: false,
        error: action.payload
      };

    case siteActions.DELETING:
      return {
        ...state,
        deleting: true,
        error: null
      };
    case siteActions.DELETE_SUCCESS:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };
    case siteActions.UPDATING:
      return {
        ...state,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: true,
        deleting: false,
        error: null
      };
    case siteActions.UPDATING_SUCCESS:
      return {
        ...state,
        data: action.payload,
        // list: [...state.list, ...action.payload],
        fetching: false,
        editing: false,
        deleting: false,
        error: null
      };

    default:
      return { ...state };
  }
};
export default reducer;
