import * as actionCreators from '../actionTypes';

const initialState = {
  productList: [],
  allProducts: [],
  listloading: false,
  listerror: null,

  deleteMessage: null,
  isDelete: false,

  submitMessage: null,
  isSubmitted: false,
  product: null,

  productEditData: null,
  productEditDataLoading: false,
  productEditDataError: null,

  productAddError: null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // Product List
    case actionCreators.PRODUCT_LIST_CHECK:
      return {
        ...state,
        listloading: true,
        productList: [],
        isDelete: false
      };

    case actionCreators.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        listloading: false,
        isDelete: false
      };
    case actionCreators.PRODUCT_LIST_FAIL:
      return {
        ...state,
        listerror: action.payload.request && action.payload.request.statusText,
        listloading: false,
        isDelete: false
      };

    case actionCreators.ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: action.payload
      };

    // Product Delete
    case actionCreators.PRODUCT_DELETE_CHECK:
      return {
        ...state,
        productList: [],
        isDelete: false
      };
    case actionCreators.PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        isDelete: true,
        deleteMessage: action.payload
      };
    case actionCreators.PRODUCT_DELETE_FAIL:
      return {
        ...state,
        isDelete: false
      };

    // Product Add
    case actionCreators.PRODUCT_ADD_CHECK:
      return {
        ...state,
        productList: [],
        isSubmitted: false
      };
    case actionCreators.PRODUCT_ADD_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        product: action.payload
      };
    case actionCreators.PRODUCT_ADD_FAIL:
      return {
        ...state,
        productAddError:
          action.payload.request && action.payload.request.statusText,
        isSubmitted: false
      };

    // Product Edit
    // edit user
    case actionCreators.PRODUCT_EDIT_CHECK:
      return {
        ...state,
        productList: [],
        productEditDataLoading: true,
        isDelete: false
      };

    case actionCreators.PRODUCT_EDIT_SUCCESS:
      return {
        ...state,
        productEditData: action.payload,
        productEditDataLoading: false,
        isDelete: false
      };
    case actionCreators.PRODUCT_EDIT_FAIL:
      return {
        ...state,
        productEditDataError:
          action.payload.request && action.payload.request.statusText,
        productEditDataLoading: false,
        isDelete: false
      };

    // Service UPDATE
    case actionCreators.PRODUCT_UPDATE_CHECK:
      return {
        ...state,
        productList: [],
        isSubmitted: false
      };
    case actionCreators.PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.PRODUCT_UPDATE_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    default:
      return { ...state };
  }
};
export default productReducer;
