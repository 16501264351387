import axios from 'axios';
import { base_url } from 'env';
import { getHeaders, parseAndShowMessage } from 'store/common/common';
import { store } from 'store/store';
import Geocode from 'react-geocode';
import moment from 'moment';

import qs from 'qs';

Geocode.setApiKey('AIzaSyADo-Lidh5uCF392j79_-pooszeTD83mj4');

axios.defaults.baseURL = base_url;
axios.interceptors.request.use(
  function(options) {
    options.headers = getHeaders();
    options.paramsSerializer = function(params) {
      return qs.stringify(params);
    };
    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const prefix = 'EVENT';

const eventActions = {
  // ACTION TYPES
  FETCHING: `${prefix}_FETCHING`,
  FETCH_ALL_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  DELETING: `${prefix}_DELETING`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  UPDATING: `${prefix}_UPDATING`,
  UPDATING_SUCCESS: `${prefix}_UPDATING_SUCCESS`,
  ERROR: `${prefix}_ERROR`,

  // ACTIONS
  getAll: (params = null) => dispatch => {
    dispatch({ type: eventActions.FETCHING });

    axios
      .post(`${base_url}event/getAll`, {
        params: params
      })
      .then(response =>
        dispatch({
          type: eventActions.FETCH_ALL_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: eventActions.ERROR, payload: error }));
  },
  getById: id => dispatch => {
    dispatch({ type: eventActions.FETCHING });

    axios
      .get(`${base_url}event/${id}`)
      .then(res =>
        dispatch({
          type: eventActions.FETCH_ONE_SUCCESS,
          payload: res.data
        })
      )
      .catch(error => dispatch({ type: eventActions.ERROR, payload: error }));
  },
  getByModel: (model, modelId, sybtype = false) => dispatch => {
    dispatch({ type: eventActions.FETCHING });

    axios
      .get(`${base_url}event/${model}/${modelId}${sybtype ? `?subtype=${sybtype}` : ''}`)
      .then(response =>
        dispatch({
          type: eventActions.FETCH_ALL_SUCCESS,
          payload: response.data
        })
      )
      .catch(error => dispatch({ type: eventActions.ERROR, payload: error }));
  },
  editStatus: (id, status) => async dispatch => {
    dispatch({ type: eventActions.DELETING });

   await axios
      .post(base_url + `event/${id}/status`, status)
      .then(response => {
        parseAndShowMessage(
          'success',
          `You have changed the status to ${status.name}`,
          'Status Update'
        );
        dispatch({
          type: eventActions.DELETE_SUCCESS,
          payload: response.data
        });
        return response.data;
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Status Update');
        dispatch({ type: eventActions.ERROR, payload: error });
      });
  },
  delete: id => async dispatch => {
    dispatch({ type: eventActions.DELETING });

   await axios
      .delete(`${base_url}event/${id}`)
      .then(response => {
        parseAndShowMessage('success', response, 'Delete Event');
        dispatch({
          type: eventActions.DELETE_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Delete Event');
        dispatch({ type: eventActions.ERROR, payload: error });
      });
  },
  update: (id, data) => dispatch => {
    const event = data[Object.keys(data)[0]];
    // data.start = moment(data.start)
    //   .utc()
    //   .format('YYYY-MM-DD hh:mm:ss');
    // data.end = moment(data.end)
    //   .utc()
    //   .format('YYYY-MM-DD hh:mm:ss');
    // data.users = data.users.map(e => e.id);

    // ...event,
    // start: moment(event.startDate)
    //   .utc()
    //   .format('YYYY-MM-DD hh:mm:ss'),
    // //   .utc()
    // //   .format('YYYY-MM-DD hh:mm:ss'),
    // end: moment(event.endDate)
    //   .utc()
    //   .format('YYYY-MM-DD hh:mm:ss'),
    // //   .utc()
    // //   .format('YYYY-MM-DD hh:mm:ss'),
    // users: event.users

    dispatch({ type: eventActions.UPDATING });

    axios
      .put(base_url + `event/${id}`, {
        ...event,
        start: moment(event.startDate)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        //   .utc()
        //   .format('YYYY-MM-DD hh:mm:ss'),
        end: moment(event.endDate)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        //   .utc()
        //   .format('YYYY-MM-DD hh:mm:ss'),
        users: event.users
      })
      .then(response => {
        parseAndShowMessage('success', response, 'Update Event', true);
        dispatch({
          type: eventActions.UPDATING_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        parseAndShowMessage('error', error, 'Update Event');
        dispatch({ type: eventActions.ERROR, payload: error });
      });
  }
};

export default eventActions;
